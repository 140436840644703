import PropTypes from 'prop-types'
import { Autocomplete, Avatar, Card, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { stringToColor } from '../../utils/cssStyles'
import { useLocales } from '../../locales'

BankAccountCard.propTypes = {
  bankAccounts: PropTypes.array,
  onChangeBankAccount: PropTypes.func,
  sx: PropTypes.object,
}
export default function BankAccountCard({ bankAccounts, onChangeBankAccount, sx, ...other }) {
  const [options, setOptions] = useState([])
  const [value, setValue] = useState(options[0])
  const [selectedBankAccount, setSelectedBankAccount] = useState({
    isSelected: false,
    bank: {},
  })
  const { translate } = useLocales()

  useEffect(() => {
    const bankAccountsArr = bankAccounts.map((item) => ({
      id: item.id,
      label: item.bank?.name,
    }))
    setOptions(bankAccountsArr)
  }, [setOptions, bankAccounts])

  const handleChange = useCallback(
    (event, newValue) => {
      if (newValue) {
        const selectedBank = bankAccounts.find((bankAccount) => bankAccount.id === newValue?.id)
        setSelectedBankAccount({ ...selectedBank, isSelected: true })
        onChangeBankAccount(event, newValue)
        setValue(newValue)
      } else {
        setValue(options[0])
        setSelectedBankAccount({ isSelected: false })
        onChangeBankAccount(event, null)
      }
    },
    [bankAccounts, onChangeBankAccount, options]
  )

  return (
    <Stack sx={{ mb: 2, ...sx }} {...other}>
      <Autocomplete
        options={options}
        value={value}
        onChange={(event, newValue) => handleChange(event, newValue)}
        renderInput={(params) => (
          <TextField {...params} label={translate('transfers.quickTransfer.bankAccount')} />
        )}
      />

      {selectedBankAccount.isSelected && (
        <Card
          sx={{
            p: 2,
            mt: 1,
            width: 1,
            maxWidth: '100%',
            mx: 'auto',
            boxShadow: 0,
            border: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar
              sx={{
                width: 48,
                height: 48,
                bgcolor: stringToColor(selectedBankAccount.bank.name),
              }}
              children={selectedBankAccount.bank.name?.charAt(Boolean)}
            />

            {/*
            <Box
              component="img"
              src="/assets/icons/files/ic_folder.svg"
              sx={{ width: 40, height: 40 }}
            />
            */}
            <div>
              <Typography variant="subtitle2">{selectedBankAccount.bank.name}</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {`
               ${translate('transfers.confirmModal.agency')} : ${selectedBankAccount.agency} - ${
                  selectedBankAccount.agency_digit ?? 'X'
                }
               ${translate('transfers.confirmModal.account')}: ${selectedBankAccount.account} - ${
                  selectedBankAccount.account_digit ?? 'X'
                }
              `}
              </Typography>
            </div>
          </Stack>
        </Card>
      )}
    </Stack>
  )
}
