import PropTypes from 'prop-types'
// @mui
import { List, Stack } from '@mui/material'
// locales
import { useLocales } from '../../../locales'
//
import { StyledSubheader } from './styles'
import NavList from './NavList'
import Can from '../../can'
import { If } from '../../conditional'

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
}

export default function NavSectionVertical({ data, sx, ...other }) {
  const { translate } = useLocales()

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title

        // Aqui renderiza a navegação.
        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            <If condition={group.subheader}>
              <Can perform={group.permission}>
                <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>
              </Can>
            </If>

            {group.items.map((list) => (
              <Can key={list.title + list.path} perform={list.permission}>
                <NavList
                  key={list.title + list.path}
                  data={list}
                  depth={1}
                  hasChild={!!list.children}
                />
              </Can>
            ))}
          </List>
        )
      })}
    </Stack>
  )
}
