import { useEffect, useState } from 'react'

export default function useColors() {
  const [colors, setColors] = useState({})

  const mockColors = () => ({
    receipts: {
      status: {
        accredited: 'success',
        chargeback: 'warning',
        canceled: 'error',
      },
    },
    releases: {
      status: {
        accredited: 'success',
        canceled: 'error',
        chargeback: 'warning',
        pending: 'default',
      },
    },
    charges: {
      status: {
        processing: 'default',
        pending: 'default',
        paid: 'success',
        canceled: 'error',
      },
    },
  })

  useEffect(() => {
    setColors(mockColors)
  }, [setColors])

  return { colors }
}
