import { forwardRef, useState } from 'react'
import {
  Alert,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import axios from '../../../../utils/axios'
import { getResponseError } from '../../../../utils/laravel'
import { useSnackbar } from '../../../../components/snackbar'
import { stringToColor } from '../../../../utils/cssStyles'
import { numberToBRL } from '../../../../utils/formatNumber'
import { useLocales } from '../../../../locales'
import { useBalance } from '../../../../hooks/balance'

const ConfirmTransferModal = forwardRef(({ open, amount, bankAccount, onClose, onSave }, ref) => {
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState()
  const { translate } = useLocales()
  const { refresh } = useBalance()

  const handleSave = async () => {
    try {
      setIsSaving(true)
      await axios.post(`/api/transfers`, {
        value: amount * 100,
        bank_account_id: bankAccount?.id,
      })
      enqueueSnackbar(translate('transfers.confirmModal.success'))
      refresh()
      onClose()
      onSave()
    } catch (e) {
      enqueueSnackbar(getResponseError(e), {
        variant: 'error',
      })
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs" onClose={onClose} ref={ref}>
      <DialogTitle>{translate('transfers.confirmModal.to')}</DialogTitle>

      <Alert sx={{ mx: 3 }} severity="success">
        {translate('transfers.confirmModal.alert')}
      </Alert>
      <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar
            sx={{ width: 48, height: 48, bgcolor: stringToColor(bankAccount?.bank?.name) }}
            children={bankAccount?.bank?.name?.charAt(Boolean)}
          />

          <div>
            <Typography variant="subtitle2">{bankAccount?.bank?.name}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`
                     ${translate('transfers.confirmModal.agency')} : ${bankAccount?.agency} - ${
                bankAccount?.agency_digit ?? 'X'
              }
                     ${translate('transfers.confirmModal.account')}: ${bankAccount?.account} - ${
                bankAccount?.account_digit ?? 'X'
              }
                    `}
            </Typography>
          </div>
        </Stack>

        <Stack direction="row" justifyContent="center" spacing={1}>
          <Typography variant="h5">{numberToBRL(amount)}</Typography>
        </Stack>
      </Stack>

      <DialogActions>
        <LoadingButton size="large" variant="contained" loading={isSaving} onClick={handleSave}>
          {translate('transfers.confirmModal.confirm')}
        </LoadingButton>

        <Button onClick={onClose}>{translate('general.cancel')}</Button>
      </DialogActions>
    </Dialog>
  )
})

ConfirmTransferModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  amount: PropTypes.number,
  bankAccount: PropTypes.object,
}

export default ConfirmTransferModal
