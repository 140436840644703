import './locales/i18n'
import 'simplebar-react/dist/simplebar.min.css'
import 'react-lazy-load-image-component/src/effects/blur.css'

import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'

import { store, persistor } from './redux/store'
import Router from './routes'
import ThemeProvider from './theme'
import ThemeLocalization from './locales'
import SnackbarProvider from './components/snackbar'
import ScrollToTop from './components/scroll-to-top'
import { MotionLazyContainer } from './components/animate'
import { ThemeSettings, SettingsProvider } from './components/settings'
import { AuthProvider } from './auth/JwtContext'
import { BalanceProvider } from './hooks/balance'
import { QueryProvider } from './hooks/query'
import { AlertProvider } from './hooks/alert'
import { WhiteLabelProvider } from './hooks/useWhiteLabel'
import AppHeaders from './components/AppHeaders'
import { WhiteLabelSettingsProvider } from './hooks/useWhiteLabelSettings'
import { App as CapacitorApp } from '@capacitor/app'
import { PATH_AUTH, PATH_DASHBOARD } from './routes/paths'

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp()
  } else {
    if ([PATH_AUTH, PATH_DASHBOARD].includes(window.location.path)) {
      window.refresh()
    } else {
      window.history.back()
    }
  }
})

export default function App() {
  return (
    <SettingsProvider>
      <WhiteLabelProvider>
        <WhiteLabelSettingsProvider>
          <AuthProvider>
            <HelmetProvider>
              <AppHeaders />
              <ReduxProvider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BalanceProvider>
                      <BrowserRouter>
                        <ScrollToTop />
                        <MotionLazyContainer>
                          <ThemeProvider>
                            <ThemeSettings>
                              <ThemeLocalization>
                                <SnackbarProvider>
                                  <QueryProvider>
                                    <AlertProvider>
                                      {/* <StyledChart /> */}
                                      <Router />
                                    </AlertProvider>
                                  </QueryProvider>
                                </SnackbarProvider>
                              </ThemeLocalization>
                            </ThemeSettings>
                          </ThemeProvider>
                        </MotionLazyContainer>
                      </BrowserRouter>
                    </BalanceProvider>
                  </LocalizationProvider>
                </PersistGate>
              </ReduxProvider>
            </HelmetProvider>
          </AuthProvider>
        </WhiteLabelSettingsProvider>
      </WhiteLabelProvider>
    </SettingsProvider>
  )
}
