/* eslint-disable no-undef */
import { useCallback, useMemo } from 'react'
import { useAuthContext } from '../auth/useAuthContext'

window.$_PowerZAP = {
  defaultCountry: '+55',
  uuid: '5c82d357-1cfe-447e-89a7-cdd8e8105700',
  company: '351668',
  afterLoad() {
    Huggy.hideButton()
    Huggy.hideChats()

    Huggy.subscribe('box-closed', () => {
      Huggy.hideButton()
      Huggy.hideChats()
    })
  },
}
;((i, s, o, g, r, a, m) => {
  i[r] = { context: { id: 'ecbabfd94321235917747747631c3bee' } }
  a = o
  o = s.createElement(o)
  o.async = 1
  o.src = g
  m = s.getElementsByTagName(a)[0]
  m.parentNode.insertBefore(o, m)
})(window, document, 'script', 'https://js.huggy.chat/widget.min.js', 'pwz')

export function useHuggy() {
  const { user, partner } = useAuthContext()

  const handleOpenChat = useCallback(async () => {
    const [firstName] = user?.name?.split(' ') || []
    await Huggy.setName(`${firstName} [${partner.name} #${partner?.id}]`)
    await Huggy.setEmail(`${user?.email}`)
    await Huggy.setData({
      name: `[${partner?.id}] ${partner?.name}`,
      mobile: `55${user?.phone}`,
      email: user?.email,
    })
    Huggy.openBox()
  }, [user, partner])

  return useMemo(() => ({ handleOpenChat }), [handleOpenChat])
}
