import React, { useMemo } from 'react'

import PropTypes from 'prop-types'
import { useAuthContext } from '../../auth/useAuthContext'

export const can = (user, perform) =>
  user?.permissions?.includes?.(perform) || perform === undefined

const Can = ({ perform, children }) => {
  const { user } = useAuthContext()

  const hasPermission = useMemo(
    () => (Array.isArray(perform) ? perform.some((p) => can(user, p)) : can(user, perform)),
    [user, perform]
  )

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return hasPermission ? children : <></>
}

Can.propTypes = {
  perform: PropTypes.oneOf(PropTypes.string, PropTypes.array),
  children: PropTypes.any.isRequired,
}

export default Can
