function useCopyToClipboard() {
  const copy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return false
    }

    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch (error) {
      console.warn('Copy failed', error)
      return false
    }
  }

  const paste = async () => {
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported')
      return null
    }

    try {
      return await navigator.clipboard.readText()
    } catch (error) {
      console.warn('Paste failed', error)
      return null
    }
  }

  return { copy, paste }
}

export default useCopyToClipboard
