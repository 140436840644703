const cardPropTypes = ['caption', 'icon', 'image', 'info', 'status', 'title', 'value', 'actions']

export const getListCards = (items, columns) => {
  const cards = items?.map?.((item) => {
    const mappedItem = columns?.reduce((result, column) => {
      const isCardType = cardPropTypes.includes(column?.card?.type)

      if (isCardType) {
        column = { ...column, ...column?.card } // override column props/functions with card options

        const data = {
          value: item[column?.field],
          row: item,
        }

        if (column?.type === 'actions') {
          result[column?.type] = column?.getActions(data)
          return result
        }

        if (column?.valueFormatter) {
          result[column?.type] = column?.valueFormatter(data)
          return result
        }

        if (column?.valueGetter) {
          result[column?.type] = column?.valueGetter(data)
          return result
        }

        result[column?.type] = item[column?.field]
      }

      return result
    }, {})

    return { ...mappedItem, row: item }
  }, [])

  return cards || []
}
