export const nextPage = async (pagination, goToNextPage) => {
  const obj = {
    page: pagination.current_page + 1,
    limit: pagination.limit || null,
  }

  if (obj.page > 1 && pagination.current_page === pagination.last_page) {
    return
  }

  await goToNextPage(obj)
}
