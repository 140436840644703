import { Navigate, useRoutes } from 'react-router-dom'
import AuthGuard from '../auth/AuthGuard'
import GuestGuard from '../auth/GuestGuard'
import CompactLayout from '../layouts/compact'
import DashboardLayout from '../layouts/dashboard'

import {
  AffiliatePage,
  BankAccountsListPage,
  BetterProductDetailsPage,
  BetterProductListPage,
  CheckoutPage,
  ComingSoonPage,
  ConfigsPage,
  DevicesListPage,
  ExtractListPage,
  GeneralBankingPage,
  IntegrationsDetailsPage,
  IntegrationsListPage,
  IntegrationsTokenPage,
  IntegrationsWebhookPage,
  ChargeFormPage,
  LoginPage,
  MaintenancePage,
  NewPasswordPage,
  OfferCheckoutPage,
  OfferCheckoutUpsell,
  OfferCreatePage,
  OfferDetailsPage,
  OfferEditPage,
  OffersListPage,
  OrderDetailsPage,
  OrdersListPage,
  Page403,
  Page404,
  Page500,
  PartnersDetailsPage,
  PartnersEditPage,
  PartnersListPage,
  PaymentPage,
  PlanCreatePage,
  PlanDetailsPage,
  PlanEditPage,
  PlansListPage,
  ProductCreatePage,
  ProductDetailsPage,
  ProductEditPage,
  ProductListPage,
  ReceiptReportListPage,
  ReceiptsListPage,
  RegisterPage,
  ReleasesListPage,
  ResetPasswordPage,
  SplitListPage,
  TransfersListPage,
  ChargeVoucher,
  TransferReportListPage,
  CheckoutStatusPage,
  AdminDashboardPage,
  PixPaymentListPage,
  PaymentLinkListPage,
  ChargesListPage,
  GatewayPage,
  GatewayDetailsPage,
} from './elements'
import {
  PATH_AFTER_LOGIN,
  WHITELABEL_PATH_AFTER_LOGIN,
  WHITELABEL_PATH_AFTER_LOGIN_FALLBACK,
} from './paths'
import { useAuthContext } from '../auth/useAuthContext'
import { useMemo } from 'react'

export default function Router() {
  const { user } = useAuthContext()

  const dashboard = useMemo(() => {
    const isWhitelabel = user?.roles.includes('WHITELABEL')
    const hasReportsDashboard = user?.permissions.includes('reports:dashboard')

    if (isWhitelabel && hasReportsDashboard) {
      return WHITELABEL_PATH_AFTER_LOGIN
    }

    if (isWhitelabel) {
      return WHITELABEL_PATH_AFTER_LOGIN_FALLBACK
    }

    return PATH_AFTER_LOGIN
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id])

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          element: <CompactLayout />,
          children: [
            {
              path: 'register',
              element: (
                <GuestGuard>
                  <RegisterPage />
                </GuestGuard>
              ),
            },
          ],
        },
        {
          element: <CompactLayout />,
        },
      ],
    },

    // Checkout antigo
    {
      path: 'pay',
      children: [
        { path: ':id', element: <OfferCheckoutPage /> },
        { path: ':id/widget', element: <OfferCheckoutUpsell /> },
      ],
    },
    {
      path: 'pay',
    },
    {
      path: 'payment',
      children: [{ path: ':id', element: <PaymentPage /> }],
    },

    // Checkout novo
    {
      path: 'c',
      children: [
        { path: ':id/pay', element: <CheckoutPage /> },
        { path: ':id/status', element: <CheckoutStatusPage /> },
      ],
    },

    // affiliation
    {
      path: 'affiliate',
      children: [
        {
          path: ':product_id',
          element: (
            <AuthGuard>
              {' '}
              <AffiliatePage />{' '}
            </AuthGuard>
          ),
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={dashboard} replace />,
          index: true,
        },
        { path: 'app', element: <GeneralBankingPage /> },
        {
          path: 'extracts',
          children: [{ path: 'list', element: <ExtractListPage /> }],
        },
        {
          path: 'receipts',
          children: [
            { path: 'list', element: <ReceiptsListPage /> },
            { path: 'releases/list', element: <ReleasesListPage /> },
            { path: 'orders/list', element: <OrdersListPage /> },
            { path: 'orders/:id', element: <OrderDetailsPage /> },
          ],
        },
        {
          path: 'offers',
          children: [
            { path: 'list', element: <OffersListPage /> },
            { path: 'new', element: <OfferCreatePage /> },
            { path: ':id/edit', element: <OfferEditPage /> },
            { path: ':id', element: <OfferDetailsPage /> },
          ],
        },
        {
          path: 'splits',
          children: [{ path: 'list', element: <SplitListPage /> }],
        },
        {
          path: 'bank-accounts',
          children: [{ path: 'list', element: <BankAccountsListPage /> }],
        },
        {
          path: 'transfers',
          children: [{ path: 'list', element: <TransfersListPage /> }],
        },
        {
          path: 'products/v2',
          children: [
            { path: ':id', element: <BetterProductDetailsPage /> },
            { path: 'list', element: <BetterProductListPage /> },
            { path: ':id/edit', element: <ProductEditPage /> },
          ],
        },
        {
          path: 'products',
          children: [
            { path: ':id', element: <ProductDetailsPage /> },
            { path: 'list', element: <ProductListPage /> },
            { path: 'new', element: <ProductCreatePage /> },
            { path: ':id/edit', element: <ProductEditPage /> },
          ],
        },
        {
          path: 'plans',
          children: [
            { path: 'list', element: <PlansListPage /> },
            { path: 'new', element: <PlanCreatePage /> },
            { path: ':id', element: <PlanDetailsPage /> },
            { path: ':id/edit', element: <PlanEditPage /> },
          ],
        },
        {
          path: 'configs',
          element: <ConfigsPage />,
        },
        {
          path: 'partners',
          children: [
            { path: ':id', element: <PartnersDetailsPage /> },
            { path: 'list', element: <PartnersListPage /> },
            { path: ':id/edit', element: <PartnersEditPage /> },
          ],
        },
        {
          path: 'reports',
          children: [
            { path: 'receipts', element: <ReceiptReportListPage /> },
            { path: 'transfers', element: <TransferReportListPage /> },
            { path: 'dashboard', element: <AdminDashboardPage /> },
          ],
        },
        {
          path: 'integrations',
          children: [
            { path: 'list', element: <IntegrationsListPage /> },
            { path: ':id', element: <IntegrationsDetailsPage /> },
            { path: 'tokens', element: <IntegrationsTokenPage /> },
            { path: 'webhooks', element: <IntegrationsWebhookPage /> },
            { path: 'gateways', element: <GatewayPage /> },
            { path: 'gateways/:id', element: <GatewayDetailsPage /> },
          ],
        },
        {
          path: 'devices',
          children: [{ path: 'list', element: <DevicesListPage /> }],
        },
        {
          path: 'payments',
          children: [
            { path: 'pix', element: <PixPaymentListPage /> },
            { path: 'payment-link', element: <PaymentLinkListPage /> },
          ],
        },
        {
          path: 'charges',
          children: [
            { path: 'list', element: <ChargesListPage /> },
            { path: 'form', element: <ChargeFormPage /> },
            { path: ':id', element: <ChargeVoucher /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      children: [
        {
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
          index: true,
        },
        {
          element: <CompactLayout />,
          children: [
            { path: 'forgot-password', element: <ResetPasswordPage /> },
            { path: 'reset-password', element: <NewPasswordPage /> },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoonPage /> },
        { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ])
}
