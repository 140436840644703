import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Button,
  debounce,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import Dialog from '@mui/material/Dialog'
import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { DataGrid } from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'
import FormProvider, { RHFSelect, RHFTextField } from '../hook-form'
import MaskedInput from '../mask/MaskedInput'
import { centsToBRL, currency } from '../../utils/formatNumber'
import Render from '../conditional/Render'
import axios from '../../utils/axios'
import { getResponseError } from '../../utils/laravel'
import { CloseIcon } from '../../theme/overrides/CustomIcons'

const SimulatorModal = forwardRef(({ onClose, ...props }, ref) => {
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [taxesWith, setTaxesWith] = useState('me')
  const [result, setResult] = useState([])
  const [spreads, setSpreads] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  const methods = useForm({
    defaultValues: {
      taxes_with: 'me',
    },
  })

  const handleChange = useMemo(
    () =>
      debounce((values) => {
        if (values.amount && values.taxes_with) {
          setIsLoading(true)
          const payFees = values.taxes_with === 'me'

          const payload = {
            pay_pix_fee: payFees,
            pay_card_fee_installment: payFees ? 24 : null,
            pay_fixed_fee_amount: payFees,
            pay_fixed_fee_percentage: payFees,
            amount: values.amount,
            spread_id: values.spread_id || null,
          }

          if (payFees) {
            delete payload.spread_id
          }

          axios
            .post('api/simulator/simulate', payload)
            .then(({ data }) => {
              setResult(data)
            })
            .catch((error) => {
              setResult([])
              enqueueSnackbar(getResponseError(error), {
                variant: 'error',
              })
            })
            .finally(() => setIsLoading(false))
        }
      }, 750),
    [enqueueSnackbar]
  )

  const { reset, watch, setValue } = methods

  const formData = watch()

  useEffect(() => {
    handleChange(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.amount, formData?.taxes_with, formData?.spread_id, handleChange])

  const handleClose = useCallback(() => {
    setResult([])
    reset()
    onClose()
    setOpen(false)
  }, [onClose, reset])

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
    close: handleClose,
  }))

  const handleTaxesWith = useCallback(
    (value) => {
      setTaxesWith(value)
      setValue('taxes_with', value)
    },
    [setValue]
  )

  const columns = useMemo(
    () => [
      {
        headerName: 'Nº de Parcelas',
        field: 'installment',
        valueGetter: (params) => {
          switch (params.row.installment) {
            case 1000: {
              return 'Pix'
            }
            case 0: {
              return 'Débito'
            }
            default: {
              return `${params.row.installment}x`
            }
          }
        },
        flex: 1,
      },
      {
        headerName: 'Valor da parcela',
        field: 'amount',
        valueGetter: (params) => centsToBRL(params?.row?.amount),
        flex: 1,
      },
      {
        headerName: 'Líquido',
        field: 'net_total',
        valueGetter: (params) => centsToBRL(params?.row?.net_total),
        flex: 1,
      },
      {
        headerName: 'Total',
        valueGetter: (params) => centsToBRL(params?.row?.total),
        field: 'total',
        flex: 1,
      },
    ],
    []
  )

  const onOpen = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data: response } = await axios.get('api/simulator-spreads')
      const { data } = response
      setSpreads(data)
    } catch (error) {
      enqueueSnackbar(getResponseError(error), {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }, [enqueueSnackbar])

  return (
    <Dialog onTransitionEnter={onOpen} fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <FormProvider methods={methods}>
        <DialogTitle>Simular Venda</DialogTitle>

        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <Grid container spacing={2} rowSpacing={2} mt={1} mb={3}>
            <Grid item xs={12}>
              <RHFTextField
                name="amount"
                label="Valor"
                type="tel"
                placeholder="0,00"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box component="span" sx={{ color: 'text.disabled' }}>
                        R$
                      </Box>
                    </InputAdornment>
                  ),
                  inputComponent: MaskedInput,
                  inputProps: { mask: currency },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ToggleButtonGroup
                color="primary"
                fullWidth
                value={taxesWith}
                exclusive
                onChange={(event, newValue) => handleTaxesWith(newValue)}
                size="medium"
              >
                <ToggleButton value="me">Eu assumo as taxas</ToggleButton>
                <ToggleButton value="customer">O Cliente assume as taxas</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Render if={taxesWith === 'customer'}>
            <Grid item xs={4}>
              <RHFSelect native name="spread_id" label="Retorno" fullWidth>
                <option value="" />
                {spreads.map((option) => (
                  <option key={option?.id} value={option?.id}>
                    {option?.label}
                  </option>
                ))}
              </RHFSelect>
            </Grid>
          </Render>

          {isLoading && (
            <Box sx={{ width: '100%', my: 4 }}>
              <LinearProgress />
            </Box>
          )}

          <Render if={result?.length > 0 && !isLoading}>
            <Box sx={{ mt: 4 }}>
              <DataGrid
                autoHeight
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                hideFooter
                hideFooterPagination
                getRowId={(row) => row.installment}
                columns={columns}
                rows={result}
                density="compact"
              />
            </Box>
          </Render>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" size="mini" color="inherit" onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  )
})

SimulatorModal.defaultProps = {
  onClose: () => {},
}

SimulatorModal.propTypes = {
  onClose: PropTypes.func,
}

export default SimulatorModal
