import { Helmet } from 'react-helmet-async'
import { useWhiteLabel } from '../../hooks/useWhiteLabel'

export default function AppHeaders() {
  const whiteLabel = useWhiteLabel()

  return (
    <>
      <Helmet titleTemplate={`%s | ${whiteLabel.name}`} />

      <Helmet>
        {/* <!-- Main Link Tags  --> */}
        <link href={whiteLabel.logo?.favicon} rel="icon" type="image/png" />

        {/* <!-- iOS  --> */}
        <link href={whiteLabel.logo?.app} rel="apple-touch-icon" />
        <link rel="apple-touch-startup-image" href={whiteLabel.logo?.app} />

        {/* <!-- Startup Image  --> */}
        <link href={whiteLabel.logo?.app} rel="apple-touch-startup-image" />

        {/* <!-- Pinned Sites  --> */}
        <meta name="application-name" content={whiteLabel.name} />

        {/* <!-- Android  --> */}
        <meta name="theme-color" content={whiteLabel.theme?.palette.primary.main} />
        <meta name="mobile-web-app-capable" content="yes" />

        {/* <!-- iOS --> */}
        <meta name="apple-mobile-web-app-title" content={whiteLabel.name} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      </Helmet>
    </>
  )
}
