import { Suspense, lazy } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  )

const FastLoadable = (Component) => (props) =>
  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  )

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')))
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')))
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')))
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')))

// dashboard
export const GeneralAppPage = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPage')))

export const GeneralBankingPage = Loadable(
  lazy(() => import('../pages/dashboard/GeneralBankingPage'))
)

export const ProductDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/Products/ProductDetailsPage'))
)
export const ProductListPage = Loadable(
  lazy(() => import('../pages/dashboard/Products/ProductListPage'))
)
export const BetterProductListPage = Loadable(
  lazy(() => import('../pages/dashboard/BetterProducts/ProductListPage'))
)

export const BetterProductDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/BetterProducts/ProductDetailsPage'))
)
export const ExtractListPage = Loadable(
  lazy(() => import('../pages/dashboard/Extract/ExtractListPage'))
)

export const BankAccountsListPage = Loadable(
  lazy(() => import('../pages/dashboard/BankAccountsListPage'))
)

export const ReceiptsListPage = Loadable(
  lazy(() => import('../pages/dashboard/Receipts/ReceiptsListPage'))
)

export const OrdersListPage = Loadable(
  lazy(() => import('../pages/dashboard/Orders/OrdersListPage'))
)
export const ProductCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Products/ProductCreatePage'))
)

export const SplitListPage = Loadable(lazy(() => import('../pages/dashboard/Splits/SplitListPage')))

export const OffersListPage = Loadable(lazy(() => import('../pages/dashboard/OffersListPage')))
export const OfferCreatePage = Loadable(lazy(() => import('../pages/dashboard/OfferCreatePage')))

export const OfferCheckoutPage = FastLoadable(
  lazy(() => import('../pages/dashboard/OfferCheckoutPage'))
)

export const OfferCheckoutUpsell = FastLoadable(
  lazy(() => import('../pages/dashboard/OfferCheckoutUpsell'))
)

export const CheckoutPage = FastLoadable(
  lazy(() => import('../pages/dashboard/Checkout/CheckoutPage'))
)

export const CheckoutStatusPage = FastLoadable(
  lazy(() => import('../pages/dashboard/Checkout/CheckoutStatusPage'))
)

export const PaymentPage = Loadable(lazy(() => import('../pages/dashboard/PaymentPage')))

export const AffiliatePage = Loadable(
  lazy(() => import('../pages/dashboard/Affiliations/AffiliatePage'))
)

export const OfferEditPage = Loadable(lazy(() => import('../pages/dashboard/OfferEditPage')))
export const OfferDetailsPage = Loadable(lazy(() => import('../pages/dashboard/OfferDetailsPage')))
export const OrderDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/Orders/OrderDetailsPage'))
)
export const ProductEditPage = Loadable(
  lazy(() => import('../pages/dashboard/Products/ProductEditPage'))
)
export const EcommerceCheckoutPage = Loadable(
  lazy(() => import('../pages/dashboard/Products/ProductCheckoutPage'))
)
export const TransfersListPage = Loadable(
  lazy(() => import('../pages/dashboard/Transfers/TransfersListPage'))
)
export const PlansListPage = Loadable(lazy(() => import('../pages/dashboard/Plans/PlanListPage')))
export const PlanCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/Plans/PlanCreatePage'))
)
export const PlanEditPage = Loadable(lazy(() => import('../pages/dashboard/Plans/PlanEditPage')))
export const PlanDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/Plans/PlanDetailsPage'))
)
export const ReleasesListPage = Loadable(
  lazy(() => import('../pages/dashboard/Releases/ReleasesListPage'))
)
export const ConfigsPage = Loadable(lazy(() => import('../pages/dashboard/User/ConfigsPage')))
export const PartnersListPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Partners/PartnersListPage'))
)
export const PartnersDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Partners/PartnerDetailsPage'))
)
export const PartnersEditPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Partners/PartnerEditPage'))
)
export const ReceiptReportListPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Reports/Receipts/ReceiptReportListPage'))
)
export const PixPaymentListPage = Loadable(
  lazy(() => import('../pages/dashboard/Payments/PixPaymentListPage'))
)
export const PaymentLinkListPage = Loadable(
  lazy(() => import('../pages/dashboard/Payments/PaymentLinkListPage'))
)
export const ChargesListPage = Loadable(
  lazy(() => import('../pages/dashboard/Charges/ChargesListPage'))
)
export const TransferReportListPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Reports/Transfers/TransferReportListPage'))
)
export const AdminDashboardPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Reports/Dashboards/AdminDashboardPage'))
)
export const IntegrationsListPage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IntegrationsListPage'))
)
export const IntegrationsDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IntegrationDetailsPage'))
)
export const IntegrationsTokenPage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IntegrationTokenPage'))
)
export const DevicesListPage = Loadable(
  lazy(() => import('../pages/dashboard/Management/Devices/DevicesListPage'))
)
export const IntegrationsWebhookPage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IntegrationWebhookPage'))
)
export const ChargeFormPage = Loadable(
  lazy(() => import('../pages/dashboard/Charges/ChargeFormPage'))
)

export const ChargeVoucher = Loadable(
  lazy(() => import('../pages/dashboard/Charges/ChargeVoucher/index'))
)

export const GatewayPage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IntegrationGatewayPage'))
)

export const GatewayDetailsPage = Loadable(
  lazy(() => import('../pages/dashboard/Integrations/IntegrationGatewayDetails'))
)

// TEST RENDER PAGE BY ROLE
export const PermissionDeniedPage = Loadable(
  lazy(() => import('../pages/dashboard/PermissionDeniedPage'))
)

// BLANK PAGE
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')))

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')))
export const Page403 = Loadable(lazy(() => import('../pages/Page403')))
export const Page404 = Loadable(lazy(() => import('../pages/Page404')))
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')))
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')))
