import PropTypes from 'prop-types'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAuthContext } from '../auth/useAuthContext'
import { can } from '../components/can'
import axios from '../utils/axios'

const BalanceContext = createContext({})

function useBalance() {
  const context = useContext(BalanceContext)

  if (!context) {
    throw new Error('useContext must be used within an BalanceProvider')
  }

  return context
}
const BalanceProvider = ({ children }) => {
  const [totals, setTotals] = useState({})
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const { user, partner } = useAuthContext()

  const isSigned = user?.id && partner?.id

  const getBalance = useCallback(async () => {
    try {
      if (isSigned && can(user, 'partner:balance')) {
        const { data } = await axios.get('/api/balance')
        setTotals(data)
      }
    } finally {
      setIsLoading(false)
    }
  }, [user, isSigned])

  const toggle = useCallback(() => {
    setIsVisible((state) => !state)
  }, [])

  const refresh = useCallback(() => {
    if (!isLoading) {
      getBalance()
    }
  }, [getBalance, isLoading])

  useEffect(() => {
    getBalance()
  }, [isSigned, getBalance])

  return (
    <BalanceContext.Provider
      /* eslint-disable-next-line react/jsx-no-constructed-context-values */
      value={{
        totals,
        isVisible,
        isLoading,
        toggle,
        refresh,
      }}
    >
      {children}
    </BalanceContext.Provider>
  )
}

BalanceProvider.propTypes = {
  children: PropTypes.node,
}

export { BalanceProvider, useBalance }
