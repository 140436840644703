import { useSessionStorageState } from 'ahooks'
import { createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from '../utils/axios'
import { HOST_API_KEY } from '../config-global'

const WhiteLabelContext = createContext({})

export function WhiteLabelSettingsProvider({ children }) {
  const [whiteLabelSettings, setWhitelabelSettings] = useSessionStorageState('whiteLabelSettings', {
    defaultValue: {},
  })

  useEffect(() => {
    async function fetchWhitelabel() {
      try {
        const { data } = await axios.get(`${HOST_API_KEY}/api/settings`)
        setWhitelabelSettings(data)
      } catch (err) {
        console.error('Failed to fetch whiteLabelSettings configuration:', err)
      }
    }

    if (!whiteLabelSettings?.id) {
      fetchWhitelabel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WhiteLabelContext.Provider value={whiteLabelSettings}>{children}</WhiteLabelContext.Provider>
  )
}

WhiteLabelSettingsProvider.propTypes = {
  children: PropTypes.node,
}

export function useWhiteLabelSettings() {
  const context = useContext(WhiteLabelContext)
  if (context === null) {
    throw new Error('useWhiteLabel must be used within a WhiteLabelSettingsProvider')
  }
  return context
}
