import PropTypes from 'prop-types'

// @mui
import { Typography, Stack } from '@mui/material'
// components
import Logo from '../../components/logo'
import Image from '../../components/image'
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles'
import { useLocales } from '../../locales'

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
}

export default function LoginLayout({ children, illustration, title }) {
  const { translate } = useLocales()

  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: 'absolute',
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <Typography variant="h3" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          {title || translate('login.welcome')}
        </Typography>

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/illustrations/illustration_dashboard.png'}
          sx={{ maxWidth: 720 }}
        />

        <StyledSectionBg />
      </StyledSection>

      <StyledContent>
        <Stack sx={{ width: 1 }}> {children} </Stack>
      </StyledContent>
    </StyledRoot>
  )
}
