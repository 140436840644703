import PropTypes from 'prop-types'
// form
import { useFormContext, Controller } from 'react-hook-form'
// @mui
import {
  Box,
  Chip,
  Select,
  Checkbox,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  alpha,
} from '@mui/material'

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  name: PropTypes.string,
  native: PropTypes.bool,
  children: PropTypes.node,
  helperText: PropTypes.node,
  maxHeight: PropTypes.number,
}

export function RHFSelect({ name, native, children, helperText, maxHeight = 220, ...other }) {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{
            native,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    px: 1,
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                    '& .MuiMenuItem-root': {
                      px: 1,
                      borderRadius: 0.75,
                      typography: 'body2',
                    },
                  }),
                },
              },
            },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  )
}

// ----------------------------------------------------------------------

RHFMultiSelect.propTypes = {
  ...Select.propTypes,
  name: PropTypes.string,
  chip: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  checkbox: PropTypes.bool,
  placeholder: PropTypes.string,
  helperText: PropTypes.node,
  sx: PropTypes.object,
}

export function RHFMultiSelect({
  name,
  chip,
  label,
  checkbox,
  placeholder,
  helperText,
  emptyText,
  InputProps,
  options = [],
  sx,
  ...other
}) {
  const { control } = useFormContext()

  const renderValues = (selectedIds) => {
    const selectedItems = options.filter((item) => selectedIds.includes(item.value))

    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      )
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems.map((item) => (
            <Chip key={item.value} size="small" label={item.label} />
          ))}
        </Box>
      )
    }

    return selectedItems.map((item) => item.label).join(', ')
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx}>
          {label && (
            <InputLabel
              id={name}
              shrink={placeholder}
              sx={{
                '&.Mui-focused': { color: 'text.primary' },
                backgroundColor: 'background.paper',
              }}
            >
              {' '}
              {label}{' '}
            </InputLabel>
          )}

          <Select
            {...field}
            multiple
            displayEmpty={!!placeholder}
            labelId={name}
            input={<OutlinedInput fullWidth label={label} error={!!error} {...InputProps} />}
            renderValue={renderValues}
            MenuProps={{
              PaperProps: {
                sx: { px: 1, maxHeight: 280 },
              },
            }}
            {...other}
          >
            {placeholder && options.length > 0 && (
              <MenuItem
                disabled
                value=""
                sx={{
                  py: 1,
                  px: 2,
                  borderRadius: 0.75,
                  typography: 'body2',
                }}
              >
                <em> {placeholder} </em>
              </MenuItem>
            )}

            {emptyText && options.length === 0 && (
              <MenuItem
                disabled
                value=""
                sx={{
                  width: '100%',
                  py: 1,
                  px: 2,
                  borderRadius: 0.75,
                  textAlign: 'center',
                  typography: 'body2',
                }}
              >
                <em> {emptyText} </em>
              </MenuItem>
            )}

            {options.map((option) => {
              const selected = field.value.includes(option.value)

              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    py: 0.5,
                    px: 2,
                    borderRadius: 0.75,
                    typography: 'body2',
                    ...(selected && {
                      fontWeight: 'fontWeightMedium',
                    }),
                    ...(option.type === 'action' && {
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
                      ':hover': {
                        backgroundColor: (theme) => `${alpha(theme.palette.primary.main, 0.2)}`,
                      },
                    }),
                  }}
                  {...option.props}
                >
                  {checkbox && !(option.type === 'action') && (
                    <Checkbox disableRipple size="small" checked={selected} />
                  )}

                  {option.label}
                </MenuItem>
              )
            })}
          </Select>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
