import { useCallback, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { Backdrop, Button, CircularProgress, IconButton } from '@mui/material'
import { ArrowsClockwise, Eye, EyeClosed } from 'phosphor-react'
import { useNavigate } from 'react-router'
import { setPartner } from '../../auth/utils'
import { CustomAvatar } from '../custom-avatar'
import { useAuthContext } from '../../auth/useAuthContext'
import { useBalance } from '../../hooks/balance'
import Render from '../Render'
import { centsToBRL } from '../../utils/formatNumber'
import Can from '../can'
import { useLocales } from '../../locales'
import Iconify from '../iconify'
import SelectPartnerModal from '../select-partner-modal'

export default function UserBalance() {
  const { user } = useAuthContext()
  const [partnerDialog, setPartnerDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const balance = useBalance()
  const { translate } = useLocales()
  const selectedPartnerID = localStorage.getItem('partner')
  const navigate = useNavigate()

  const company = useMemo(
    () => user.partners.find((partner) => partner.id === selectedPartnerID),
    [user?.partners, selectedPartnerID]
  )

  const handlePartnerChange = useCallback(
    async (selectedPartner) => {
      setIsLoading(true)
      setPartner(selectedPartner.id)
      setTimeout(() => navigate(0), 700)
    },
    [navigate]
  )

  return (
    <>
      {isLoading && (
        <Backdrop open>
          <CircularProgress color="primary" />
        </Backdrop>
      )}

      <Stack sx={{ textAlign: 'center' }}>
        <Stack alignItems="center">
          <Box sx={{ position: 'relative' }}>
            <CustomAvatar src="/assets/avatar_5.jpg" name="Nome do user" />
            {/* Esse componente vai ser utilizado mais tarde */}

            {/* <Label */}
            {/*  color="success" */}
            {/*  variant="filled" */}
            {/*  sx={{ */}
            {/*    top: -6, */}
            {/*    px: 0.5, */}
            {/*    left: 40, */}
            {/*    height: 20, */}
            {/*    position: 'absolute', */}
            {/*    borderBottomLeftRadius: 2, */}
            {/*  }} */}
            {/* > */}
            {/*  Free */}
            {/* </Label> */}
          </Box>

          <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2, maxWidth: 240, textOverflow: 'ellipsis' }}>
            <Typography variant="subtitle2" noWrap>
              {company?.name}
              <Render if={user?.partners?.length > 1}>
                <IconButton>
                  <Iconify icon="solar:shuffle-outline" onClick={() => setPartnerDialog(true)} />
                </IconButton>
              </Render>
            </Typography>

            <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
              {company?.email}
            </Typography>
          </Stack>

          <Can perform="partner:balance">
            <Render if={!balance?.isLoading}>
              <Stack direction="row" alignItems="center" justifyItems="space-between">
                <Button
                  variant="soft"
                  color="inherit"
                  sx={{ filter: balance?.isVisible ? 'blur(0px)' : 'blur(4px)' }}
                >
                  {balance?.totals?.available < 0
                    ? 'R$ •••,••'
                    : centsToBRL(balance?.totals?.available)}
                </Button>
                <IconButton onClick={balance.toggle}>
                  {!balance.isVisible ? <EyeClosed size={20} /> : <Eye size={20} />}
                </IconButton>
                <IconButton onClick={balance.refresh}>
                  <ArrowsClockwise size={20} />
                </IconButton>
              </Stack>
            </Render>
            <Render if={!balance?.isLoading}>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {translate('availableBalance')}
              </Typography>
            </Render>
          </Can>
        </Stack>
      </Stack>

      <SelectPartnerModal
        loading={isLoading}
        open={partnerDialog}
        onClose={() => setPartnerDialog(false)}
        changePartner={handlePartnerChange}
        currentPartner={company}
      />
    </>
  )
}
