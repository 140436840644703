import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  Avatar,
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import { Image } from 'phosphor-react'
import Render from '../Render'
import Label from '../label'
import TextMaxLine from '../text-max-line'
import Iconify from '../iconify'
import { useLocales } from '../../locales'

const AppListItem = ({
  caption,
  icon: Icon,
  image,
  info,
  status,
  title,
  actions,
  value,
  row,
  onDetailsClick,
  formatValues,
  valuesToShow,
  translateLabels,
  ...props
}) => {
  const { translate } = useLocales()

  const right = (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography component="span" variant="body2" fontWeight="fontWeightBold" align="right">
        {value}

        <Typography
          sx={{ display: 'block' }}
          component="span"
          variant="caption"
          color="text.disabled"
        >
          {info}
        </Typography>
      </Typography>
    </Stack>
  )

  const getRawValue = useCallback(
    (val, key, rowValue) => {
      if (Object.keys(formatValues).includes(key)) {
        return formatValues[key](val, rowValue)
      }

      return val
    },
    [formatValues]
  )

  const formatRawTranslate = useCallback(
    (key) => {
      if (Object.keys(translateLabels).includes(key)) {
        return translateLabels[key]
      }

      return translate(`listColumns.${key}`)
    },
    [translateLabels, translate]
  )

  return (
    <Box sx={sx.card}>
      <Render if={valuesToShow.length > 0}>
        <Box sx={{ p: 2 }} onClick={onDetailsClick}>
          <Stack direction="row">
            <Grid container rowSpacing={0.8} columnSpacing={4.5}>
              {valuesToShow.map((key, idx) => (
                <Render key={row[key]} if={![null, undefined].includes(row[key])}>
                  <Grid item key={`${row[key]}-${idx}`} xs={6} sm={3}>
                    <Box>
                      <TextMaxLine line={1}>
                        <Typography variant="caption" color="textSecondary">
                          {formatRawTranslate(key)}
                        </Typography>
                      </TextMaxLine>

                      <TextMaxLine line={1}>
                        <Typography variant="subtitle2">
                          {getRawValue(row[key], key, row)}
                        </Typography>
                      </TextMaxLine>
                    </Box>
                  </Grid>
                </Render>
              ))}
            </Grid>
          </Stack>
        </Box>
      </Render>
      <Render if={valuesToShow.length === 0}>
        <ListItem
          onClick={onDetailsClick}
          alignItems="flex-start"
          component={Paper}
          secondaryAction={right}
          {...props}
        >
          <Render if={Icon || image}>
            <ListItemAvatar>
              {image ? (
                <Avatar variant="rounded" src={image}>
                  <Image weight="fill" />
                </Avatar>
              ) : (
                <Avatar variant="rounded">
                  <Iconify icon={Icon} height={24} width={24} />
                </Avatar>
              )}
            </ListItemAvatar>
          </Render>
          <ListItemText
            sx={{ maxWidth: '65%', overflow: 'hidden', textOverflow: 'ellipsis' }}
            primary={
              <Typography component="h2" variant="body2" fontWeight="fontWeightBold">
                <TextMaxLine line={1}>{title}</TextMaxLine>
              </Typography>
            }
            secondary={
              <Typography
                component="span"
                variant="caption"
                color="text.disabled"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {status && (
                  <Label variant="filled" color="secondary">
                    {status}
                  </Label>
                )}

                {status && caption && ' · '}
                {caption}
              </Typography>
            }
          />
        </ListItem>
      </Render>
      <Render if={actions}>
        <Grid container>
          {actions?.map((action, index) => (
            <Grid item key={index}>
              {action}
            </Grid>
          ))}
        </Grid>
      </Render>
    </Box>
  )
}

const sx = {
  card: {
    py: 1,
  },
  action: {
    position: 'absolute',
    top: -12,
    right: 2,
  },
}

AppListItem.defaultProps = {
  valuesToShow: [],
  formatValues: {},
  translateLabels: {},
}

AppListItem.propTypes = {
  caption: PropTypes.string,
  icon: PropTypes.string,
  image: PropTypes.any,
  info: PropTypes.string,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  style: PropTypes.any,
  value: PropTypes.string,
  actions: PropTypes.any,
  row: PropTypes.any,
  onDetailsClick: PropTypes.any,
  formatValues: PropTypes.object,
  valuesToShow: PropTypes.array,
  translateLabels: PropTypes.object,
}

export default AppListItem
