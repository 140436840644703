/* eslint-disable */
import { useNavigate, useLocation } from 'react-router-dom'

import React, { createContext, useCallback, useContext, useMemo } from 'react'

import PropTypes from 'prop-types'

const QueryContext = createContext({})

function useQuery() {
  const context = useContext(QueryContext)

  if (!context) {
    throw new Error('useContext must be used within an QueryProvider')
  }

  return context
}

const QueryProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const getQuery = useCallback(() => new URLSearchParams(location.search), [location.search])

  const getQueryParams = useCallback((dot) => parseQueryString(getQuery(), dot), [getQuery])

  const removeQueryParams = useCallback(
    (params) => {
      const search = new URLSearchParams(location.search)

      for (const name of params) {
        search.delete(name)
      }

      navigate({ search: search.toString() })
    },
    [navigate, location.search]
  )

  const replaceQueryParams = useCallback(
    (params) => {
      navigate({
        search: mergeSearchParams(params).toString(),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location.search]
  )

  const appendQueryParams = useCallback(
    (params) => {
      navigate({
        search: decodeURI(
          mergeSearchParams(params, new URLSearchParams(location.search)).toString()
        ),
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location.search]
  )

  const query = useMemo(() => getQueryParams(), [getQueryParams])

  return (
    <QueryContext.Provider
      value={{
        query,
        getQuery,
        getQueryParams,
        appendQueryParams,
        removeQueryParams,
        replaceQueryParams,
      }}
    >
      {children}
    </QueryContext.Provider>
  )
}

QueryProvider.propTypes = {
  children: PropTypes.any,
}

function parseQueryString(search, dot = ':') {
  const params = new URLSearchParams(search)
  const filter = {}

  for (const [key, value] of params.entries()) {
    const keys = key.match(/([\w.]+)/g)

    keys.reduce((result, currentKey, index) => {
      if (index === keys.length - 1) {
        currentKey = currentKey.replace('.', dot)
        if (value.includes(',')) {
          result[currentKey] = value.split(',')
        } else {
          result[currentKey] = value
        }
      } else {
        result[currentKey] = result[currentKey] || {}
      }
      return result[currentKey]
    }, filter)
  }

  return filter
}

/**
 * Create a search params object and merge it (optionally) with a given query string
 */
export function mergeSearchParams(params, search = new URLSearchParams(), prefix = '') {
  const isEmpty = (value) =>
    value == null || value === '' || (Array.isArray(value) && !value.length)

  for (let [key, value] of Object.entries(params)) {
    key = key.replace(/:/g, '.')
    const newKey = prefix ? `${prefix}[${key}]` : key

    if (Array.isArray(value)) {
      value = value.filter((val) => !isEmpty(val))
    }

    if (isEmpty(value)) {
      search.delete(newKey)
      continue // ingnores empty, null and undefined
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      mergeSearchParams(value, search, newKey)
    } else if (Array.isArray(value)) {
      search.set(newKey, value.join(','))
    } else {
      search.set(newKey, value)
    }
  }

  return search
}

/**
 * Does the same of getQueryParams but without been reactive or trigger rerenders
 */
function getQueryParamsOnce() {
  return Object.fromEntries(new URLSearchParams(location?.search))
}

export { useQuery, getQueryParamsOnce, QueryProvider }
