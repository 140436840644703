import { useRequest } from 'ahooks'
import { useCallback, useMemo } from 'react'
import { useSnackbar } from 'notistack'
import axiosInstance from '../utils/axios'
import { useAuthContext } from '../auth/useAuthContext'
import { useWhiteLabelSettings } from './useWhiteLabelSettings'
import { getResponseError } from '../utils/laravel'

const authenticate = async () => {
  const { data } = await axiosInstance.post('api/eventz/impersonate')
  return data
}

const getCacheTime = (minutes = 0) => minutes * 60 * 1000

const getCacheKey = (resource, partnerId) => `eventz-${resource}:${partnerId}`

export function useEventz() {
  const { partner } = useAuthContext()
  const { MS_EVENTZ_APP: whiteLabelURL } = useWhiteLabelSettings()
  const { enqueueSnackbar } = useSnackbar()

  const {
    loading: isLoading,
    error: authError,
    runAsync,
  } = useRequest(authenticate, {
    manual: true,
    retryCount: 3,
    retryInterval: 10,
    staleTime: getCacheTime(5),
    cacheKey: getCacheKey('auth', partner?.id),
    onError: (error) => enqueueSnackbar(getResponseError(error), { variant: 'error' }),
  })

  const isReady = !isLoading && !authError

  const openEventzPage = useCallback(
    (path, token) => {
      if (isReady && token) {
        const query = new URLSearchParams()

        query.append('token', token)
        query.append('returnTo', path)

        const url = `${whiteLabelURL}/auth/jwt/token?${query.toString()}`

        window.open(url, '_blank').focus()
      }
    },
    [isReady, whiteLabelURL]
  )

  const openDashboard = useCallback(async () => {
    await runAsync().then(({ access_token }) => {
      openEventzPage('/dashboard', access_token)
    })
  }, [openEventzPage, runAsync])

  return useMemo(
    () => ({
      isReady,
      isLoading,
      openDashboard,
    }),
    [isReady, isLoading, openDashboard]
  )
}
