import { useCallback, useState, useRef } from 'react'
import * as Yup from 'yup'
import { Link as RouterLink } from 'react-router-dom'
// form
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import ReCAPTCHA from 'react-google-recaptcha'
// routes
import { PATH_PASSWORD } from '../../routes/paths'
// auth
import { useAuthContext } from '../../auth/useAuthContext'
// components
import Iconify from '../../components/iconify'
import FormProvider, { RHFTextField } from '../../components/hook-form'
import { useSnackbar } from '../../components/snackbar'
import { getResponseError } from '../../utils/laravel'
import { useLocales } from '../../locales'

// ----------------------------------------------------------------------

export default function AuthLoginForm() {
  const { login } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const recaptchaRef = useRef()
  const { translate } = useLocales()

  const [showPassword, setShowPassword] = useState(false)

  const LoginSchema = Yup.object().shape({
    login: Yup.string()
      .required(translate('validators.email_is_required'))
      .email(translate('validators.shouldBeValidEmail')),
    password: Yup.string().required(translate('validators.passwordIsRequired')),
    recaptcha: Yup.string().required(translate('validators.recaptchaIsRequired')),
  })

  const defaultValues = {
    login: '',
    password: '',
    recaptcha: process.env.NODE_ENV === 'production' ? '' : 'recaptcha',
  }

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  })

  const {
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods

  const onSubmit = async (data) => {
    try {
      await login(data)
    } catch (error) {
      console.error(error)
      setError('afterSubmit', {
        ...error,
        message: getResponseError(error) || translate('login.loginError'),
      })
      enqueueSnackbar(getResponseError(error) || translate('login.loginError'), {
        variant: 'error',
      })
      recaptchaRef.current.reset()
    } finally {
      setValue('recaptcha', null)
    }
  }

  const handleVerify = useCallback(
    (token) => {
      if (token) {
        setValue('recaptcha', token)
      }
    },
    [setValue]
  )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="center">
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="login" label={translate('listColumns.email')} />

        <RHFTextField
          name="password"
          label={translate('login.password')}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_G_RECAPTCHA_KEY}
          onChange={handleVerify}
        />
        <RHFTextField type="hidden" name="recaptcha" sx={{ display: 'none' }} />
        {errors.recaptcha && (
          <Alert severity="error" variant="outlined" sx={{ marginTop: 1, border: 'none' }}>
            {errors.recaptcha.message}
          </Alert>
        )}
      </Stack>

      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          to={PATH_PASSWORD.resetPassword}
          variant="body2"
          color="inherit"
          underline="always"
        >
          {translate('login.forgotPassword')}
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
        sx={{
          bgcolor: 'text.primary',
          color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          '&:hover': {
            bgcolor: 'text.primary',
            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            '&:hover': {
              bgcolor: 'text.primary',
              color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
            },
          },
        }}
      >
        {translate('login.signin')}
      </LoadingButton>
    </FormProvider>
  )
}
