import axiosBase from 'axios'
import { HOST_API_KEY } from '../config-global'

const axios = axiosBase.create({ baseURL: HOST_API_KEY })

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

export default axios
