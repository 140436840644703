import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material'
import { useAuthContext } from '../../auth/useAuthContext'
import useLocales from '../../locales/useLocales'
import Render from '../conditional/Render'
import Iconify from '../iconify/Iconify'
import ConfirmDialog from '../confirm-dialog/ConfirmDialog'

SelectPartnerModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  currentPartner: PropTypes.object,
  changePartner: PropTypes.func,
}
export default function SelectPartnerModal({
  open,
  loading,
  onClose,
  currentPartner,
  changePartner,
  ...other
}) {
  const { user } = useAuthContext()

  const [selectedPartner, setSelectedPartner] = useState()
  const [isOpen, setIsOpen] = useState(false)

  const { translate } = useLocales()

  const handleDialogPartner = useCallback(
    (partner) => {
      if (partner?.id !== currentPartner?.id) {
        setSelectedPartner(partner)
        setIsOpen(true)
      }
    },
    [currentPartner?.id]
  )

  const handleChangePartner = useCallback(() => {
    if (selectedPartner) {
      changePartner(selectedPartner)
      setIsOpen(false)
    }
  }, [changePartner, selectedPartner])

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
        {loading && (
          <DialogContent
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 8,
            }}
          >
            <CircularProgress color="primary" />
          </DialogContent>
        )}
        <Render if={!loading}>
          <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography variant="h6" mb={2}>
              {translate('changePartner.title')}
            </Typography>
            <Divider />
          </DialogTitle>

          <DialogContent sx={{ typography: 'body2' }}>
            <Grid container spacing={2}>
              {user?.partners.map((partner) => (
                <Grid item xs={12} key={partner.id}>
                  <Link
                    underline="none"
                    onClick={() => {
                      if (partner?.id !== currentPartner?.id) {
                        handleDialogPartner(partner)
                      }
                    }}
                    sx={{
                      cursor: partner?.id === currentPartner?.id ? 'not-allowed' : 'pointer',
                      pointerEvents: partner?.id === currentPartner?.id ? 'none' : 'auto',
                    }}
                  >
                    <Card>
                      <Stack
                        sx={{
                          color:
                            partner?.id === currentPartner?.id ? 'text.primary' : 'text.secondary',
                          border: partner?.id === currentPartner?.id ? '1px solid' : 'none',
                          borderRadius: 2,
                          padding: 2,
                          '&:hover': {
                            color: 'text.primary',
                          },
                        }}
                      >
                        <Stack direction="row" spacing={1}>
                          <Iconify icon="solar:suitcase-lines-outline" />
                          <Typography variant="subtitle2">{partner?.id}</Typography>
                          <Typography variant="subtitle2">{partner?.name}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Iconify icon="solar:file-text-outline" />
                          <Typography variant="body2">{partner?.document}</Typography>
                        </Stack>
                      </Stack>
                    </Card>
                  </Link>
                  <Divider sx={{ my: 1 }} />
                </Grid>
              ))}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {translate('actions.close')}
            </Button>
          </DialogActions>
        </Render>
      </Dialog>

      <ConfirmDialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title={translate('changePartner.title')}
        content={`${translate('changePartner.content')} ${selectedPartner?.name}?`}
        action={
          <Button variant="contained" onClick={() => handleChangePartner()}>
            {translate('actions.change')}
          </Button>
        }
      />
    </>
  )
}
