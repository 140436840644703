import PropTypes from 'prop-types'
import { createContext, useEffect, useReducer, useCallback, useMemo } from 'react'
import axios from '../utils/axios'
import localStorageAvailable from '../utils/localStorageAvailable'

import { isValidToken, setPartner, setSession } from './utils'

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  partner: null,
}

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
      partner: action.payload.partner,
    }
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      partner: action.payload.partner,
    }
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
      partner: action.payload.partner,
    }
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    }
  }

  return state
}

export const AuthContext = createContext(null)

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const storageAvailable = localStorageAvailable()

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable ? localStorage.getItem('accessToken') : ''
      const selectedPartnerId = localStorage.getItem('partner')
      axios.defaults.headers.common['X-Tenant'] = process.env.REACT_APP_TENANT_ID

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken)
        setPartner(selectedPartnerId)

        const response = await axios.get('/oauth/me')

        const user = response.data

        let partner =
          user?.partners.find((p) => p.id === selectedPartnerId) || user?.partners.find(Boolean)

        if (!partner?.id && user?.roles.includes('WHITELABEL')) {
          partner = { id: selectedPartnerId }
        }

        setPartner(partner?.id)

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
            partner,
          },
        })
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        })
      }
    } catch (error) {
      console.error(error)
      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: false,
          user: null,
        },
      })
    }
  }, [storageAvailable])

  useEffect(() => {
    initialize()
  }, [initialize])

  // LOGIN
  const login = useCallback(async (data) => {
    const response = await axios.post('/oauth/token', data)
    const { access_token, user } = response.data

    const selectedPartnerId = localStorage.getItem('partner')
    const partner =
      user?.partners.find((p) => p.id === selectedPartnerId) || user?.partners.find(Boolean)

    setPartner(partner?.id)
    setSession(access_token)

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        partner,
      },
    })
  }, [])

  const impersonate = useCallback(
    async (partnerID) => {
      setPartner(partnerID)
      await initialize()
    },
    [initialize]
  )

  const register = useCallback(async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    })
    const { accessToken, user } = response.data

    const selectedPartnerId = localStorage.getItem('partner')

    const partner =
      user?.partners.find((p) => p.id === selectedPartnerId) || user?.partners.find(Boolean)

    setSession(accessToken)
    setPartner(partner?.id)

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
        partner,
      },
    })
  }, [])

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null)
    setPartner(null)
    dispatch({
      type: 'LOGOUT',
    })
  }, [])

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      partner: state.partner,
      method: 'jwt',
      login,
      register,
      logout,
      impersonate,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.partner,
      login,
      logout,
      register,
      impersonate,
    ]
  )

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>
}
