import { Box, Stack } from '@mui/material'
import Logo from '../../../components/logo'
import { NavSectionMini } from '../../../components/nav-section'
import { NAV } from '../../../config-global'
import { hideScrollbarX } from '../../../utils/cssStyles'
import NavToggleButton from './NavToggleButton'
import { useNavData } from './config-navigation'

export default function NavMini() {
  const navConfig = useNavData()
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD_MINI },
      }}
    >
      <NavToggleButton
        sx={{
          top: 55,
          left: NAV.W_DASHBOARD_MINI - 12,
        }}
      />

      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          width: NAV.W_DASHBOARD_MINI,
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX,
        }}
      >
        <Logo sx={{ mx: 'auto', my: 2, maxWidth: 64 }} />

        <NavSectionMini data={navConfig} />
      </Stack>
    </Box>
  )
}
