import { useTheme } from '@emotion/react'
import { Card, Divider, List, Stack, alpha } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import React, { memo, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import AppListItem from '../AppListItem'
import Hide from '../Hide'
import Render from '../Render'

import { merge } from 'lodash'
import { useDimentions } from '../../hooks/dimentions'
import { useQuery } from '../../hooks/query'
import { useLocales } from '../../locales'
import axios from '../../utils/axios'
import { debounce } from '../../utils/debounce'
import { nextPage } from '../../utils/pagination'
import { If } from '../conditional'
import { getListCards } from './helpers'

const DataGridRemote = React.forwardRef(
  (
    {
      endpoint,
      columns,
      onLoadData,
      onLoadingStatusChange = () => {},
      filters,
      include,
      onDetailsClick = () => {},
      responsive = true,
      mobileOptions,
      ...props
    },
    ref
  ) => {
    const theme = useTheme()
    const dimentions = useDimentions()
    const { translate } = useLocales()
    const { getQueryParams } = useQuery()

    const { enqueueSnackbar } = useSnackbar()

    const search = useMemo(() => getQueryParams('.'), [getQueryParams])

    const [loadingList, setLoadingList] = React.useState(true)
    const [listItems, setListItems] = React.useState([])
    const [pagination, setPagination] = React.useState({
      total: 0,
      itemCount: 0,
      itemsPerPage: 10,
      last_page: 0,
      current_page: 1,
    })

    const loadData = React.useCallback(
      async (options) => {
        try {
          if (options.page === 1) {
            setListItems([])
          }
          setLoadingList(true)
          const {
            data: { data, meta },
          } = await axios.get(`${endpoint}`, {
            params: merge({}, filters, search, options, { include }),
          })

          setPagination(meta)

          if (dimentions.width >= theme.breakpoints.values.md) {
            setListItems(data)
            onLoadData({ data, pagination: meta })
          } else {
            setListItems((items) => {
              const newItems = options.page === 1 ? data : items.concat(data)

              onLoadData({
                data: newItems,
                pagination: meta,
              })

              return newItems
            })
          }
        } catch (error) {
          console.warn(error)
          enqueueSnackbar(translate('dataGridRemote.tryAgainInAFewMoments'), {
            variant: 'error',
          })
        } finally {
          setLoadingList(false)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filters, onLoadData, enqueueSnackbar, search]
    )

    React.useImperativeHandle(
      ref,
      () => ({
        refresh() {
          loadData({ page: 1 })
        },
      }),
      [loadData]
    )

    const loadMore = React.useCallback(async () => {
      if (!loadingList) nextPage(pagination, loadData)
    }, [loadData, loadingList, pagination])

    const handleLoadMore = React.useMemo(() => debounce(loadMore, 750), [loadMore])

    const listCards = React.useMemo(() => getListCards(listItems, columns), [columns, listItems])

    React.useEffect(() => {
      onLoadingStatusChange(loadingList)
    }, [loadingList, onLoadingStatusChange])

    React.useEffect(() => {
      loadData({ page: 1 })
    }, [loadData])

    const isMobile = dimentions.width <= theme.breakpoints.values.md
    const showTable = !responsive || !isMobile

    return (
      <>
        <Hide if={showTable}>
          <InfiniteScroll
            pageStart={0}
            threshold={120}
            initialLoad
            loadMore={listItems.length >= 1 ? handleLoadMore : () => {}}
            hasMore={pagination.current_page !== pagination.last_page}
            loader={
              listCards?.length >= 1 ? (
                <div style={{ textAlign: 'center', padding: 24 }} key={0}>
                  {translate('dataGridRemote.loading')}
                </div>
              ) : null
            }
          >
            <Render if={listCards?.length === 0 && !loadingList}>
              <div style={{ textAlign: 'center', padding: 24 }}>
                {translate('dataGridRemote.none')}
              </div>
            </Render>
            <Card
              sx={{
                borderRadius: 1,
                boxShadow: 0,
                background: alpha(theme.palette.background.paper, 0.5),
              }}
            >
              <List>
                {listCards.map((item, index) => (
                  <>
                    <AppListItem
                      key={item.id}
                      {...item}
                      actions={item.actions}
                      onDetailsClick={() => onDetailsClick(item)}
                      {...mobileOptions}
                      {...item.props}
                    />
                    <If condition={listCards.length > 1 && index !== listCards.length - 1}>
                      <Divider />
                    </If>
                  </>
                ))}
              </List>
            </Card>
          </InfiniteScroll>
        </Hide>

        <Render if={showTable}>
          <Card
            sx={{
              overflow: 'hidden',
              borderRadius: 1,
              boxShadow: 0,
              background: alpha(theme.palette.background.paper, 0.5),
            }}
          >
            <DataGrid
              autoHeight
              autoPageSize
              disableColumnFilter
              disableColumnMenu
              disableColumnSorting
              disableColumnSelector
              disableDensitySelector
              disableRowSelectionOnClick
              disableSelectionOnClick
              pagination
              paginationMode="server"
              pageSize={pagination.per_page}
              density="comfortable"
              columns={columns}
              loading={loadingList}
              rowCount={pagination.total}
              rows={listItems}
              isRowSelectable={false}
              rowHeight={56}
              columnHeaderHeight={72}
              onPageChange={(page) => loadData({ page: page + 1 })}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    {translate('dataGridRemote.none')}
                  </Stack>
                ),
              }}
              {...props}
            />
          </Card>
        </Render>
      </>
    )
  }
)

DataGridRemote.displayName = 'DataGridRemote'

DataGridRemote.defaultProps = {
  columns: [],
  onLoadData: () => {},
  filters: {},
  onDetailsClick: () => {},
  mobileOptions: {},
}

DataGridRemote.propTypes = {
  ...DataGrid.propTypes,
  onLoadData: PropTypes.func,
  filters: PropTypes.object,
  columns: PropTypes.array,
  onDetailsClick: PropTypes.func,
  mobileOptions: PropTypes.object,
}

export default memo(DataGridRemote)
