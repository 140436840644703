/* eslint-disable no-undef */
import { CircularProgress } from '@mui/material'
import { useMemo } from 'react'
import { filter } from 'lodash'
import Iconify from '../../../components/iconify/Iconify'
import SvgColor from '../../../components/svg-color'
import { PATH_DASHBOARD } from '../../../routes/paths'

import { useAuthContext } from '../../../auth/useAuthContext'
import { useEventz } from '../../../hooks/useEventz'
import { useHuggy } from '../../../hooks/useHuggy'
import { useMembership } from '../../../hooks/useMembership'
import { useWhiteLabel } from '../../../hooks/useWhiteLabel'
import { useWhiteLabelSettings } from '../../../hooks/useWhiteLabelSettings'

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
)

const iconifyIcon = (name) => <Iconify icon={name} />

const ICONS = {
  blog: icon('ic_blog'),
  cart: icon('ic_cart'),
  bag: icon('ic_bag'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  dashboardAdmin: iconifyIcon('solar:chart-2-bold-duotone'),
  dollar: iconifyIcon('solar:dollar-bold-duotone'),
  offer: icon('ic_price_tag'),
  integrations: icon('ic_external'),
  integrationApps: iconifyIcon('solar:widget-bold-duotone'),
  integrationTokens: iconifyIcon('solar:key-minimalistic-bold-duotone'),
  integrationWebhooks: iconifyIcon('ph:webhooks-logo-duotone'),
  membership: icon('ic_academic_cap'),
  devices: icon('ic_devices'),
  eventz: icon('ic_ticket'),
  pix: iconifyIcon('ic:round-pix'),
  paymentLink: iconifyIcon('solar:link-circle-line-duotone'),
  receipts: iconifyIcon('solar:dollar-bold-duotone'),
  sales: iconifyIcon('solar:fire-bold-duotone'),
  payments: iconifyIcon('solar:money-bag-bold-duotone'),
  reports: iconifyIcon('solar:pie-chart-2-bold-duotone'),
  charges: iconifyIcon('ph:barcode-duotone'),
  transfers: iconifyIcon('solar:round-transfer-horizontal-bold-duotone'),
  gateways: iconifyIcon('carbon:gateway-api'),
  loading: <CircularProgress color="inherit" size={24} />,
}

const addPermissionsToParent = (items) => {
  return items.map((item) => {
    for (let key of ['items', 'children']) {
      if (item[key]) {
        const childrenWithPermissions = addPermissionsToParent(item[key])
        const childPermissions = childrenWithPermissions.flatMap(
          (child) => child.permission || undefined
        )

        item = {
          ...item,
          permission: [...new Set([...(item.permission || []), ...childPermissions])],
          [key]: childrenWithPermissions,
        }
      }
    }
    return item
  })
}

export function useNavData() {
  const whitelabel = useWhiteLabel()
  const { handleOpenChat } = useHuggy()
  const { openDashboard: openMembership, isLoading: isMembershipLoading } = useMembership()
  const { openDashboard: openEventz, isLoading: isEventzLoading } = useEventz()
  const { partner } = useAuthContext()
  const { MS_MEMBERS_ENABLED, MS_EVENTZ_ENABLED } = useWhiteLabelSettings()

  const adminRoutes = useMemo(
    () => [
      {
        subheader: 'backoffice',
        permission: 'partners:list',
        items: [
          {
            title: 'app',
            path: PATH_DASHBOARD.reports.dashboard,
            icon: ICONS.dashboardAdmin,
            permission: 'reports:dashboard',
          },
          {
            title: 'partners.main',
            path: PATH_DASHBOARD.partners.list,
            icon: ICONS.user,
            permission: 'partners:list',
          },

          {
            title: 'devices.main',
            path: PATH_DASHBOARD.devices.list,
            icon: ICONS.devices,
            permission: 'admin:devices:list',
          },
          {
            title: 'plans.main',
            path: PATH_DASHBOARD.plans.list,
            icon: ICONS.booking,
            permission: 'plans:list',
          },
          {
            title: 'reports.main',
            path: '#',
            icon: ICONS.reports,
            permission: 'reports:receipts',
            children: [
              {
                title: 'reports.receipts',
                path: PATH_DASHBOARD.reports.receipts,
                icon: ICONS.receipts,
                permission: 'reports:receipts',
              },
              {
                title: 'reports.transfers',
                path: PATH_DASHBOARD.reports.transfers,
                icon: ICONS.transfers,
                permission: 'reports:transfers',
              },
            ],
          },
        ],
      },
    ],
    []
  )

  const partnerRoutes = useMemo(
    () =>
      partner?.id
        ? [
            // GENERAL
            // ----------------------------------------------------------------------
            {
              subheader: 'general.main',
              items: filter([
                { title: 'dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
                {
                  title: 'receipts.menu',
                  path: '#',
                  icon: ICONS.sales,
                  children: [
                    {
                      title: 'receipts.main',
                      path: PATH_DASHBOARD.receipts.list,
                      icon: ICONS.receipts,
                      permission: 'partner-receipts:list',
                    },
                    {
                      title: 'orders.main',
                      path: PATH_DASHBOARD.receipts.orders.list,
                      icon: ICONS.cart,
                      permission: 'orders:list',
                    },
                  ],
                },
                {
                  title: 'product.products',
                  path: PATH_DASHBOARD.products.list,
                  icon: ICONS.cart,
                  permission: 'products:list',
                },
                {
                  title: 'offer.offers',
                  path: PATH_DASHBOARD.offers.list,
                  icon: ICONS.offer,
                  permission: 'offers:list',
                },
                MS_MEMBERS_ENABLED && {
                  title: 'membership.main',
                  path: '#',
                  icon: isMembershipLoading ? ICONS.loading : ICONS.membership,
                  action: openMembership,
                },
                MS_EVENTZ_ENABLED && {
                  title: 'eventz.main',
                  path: '#',
                  icon: isEventzLoading ? ICONS.loading : ICONS.eventz,
                  action: openEventz,
                },
              ]),
            },
            {
              subheader: 'banking',
              items: [
                {
                  title: 'transfers.main',
                  path: PATH_DASHBOARD.transfers.list,
                  icon: ICONS.invoice,
                  permission: 'transfers:list',
                },
                {
                  title: 'charges.main',
                  path: PATH_DASHBOARD.charges.list,
                  icon: ICONS.charges,
                  permission: 'charges:list',
                },
                {
                  title: 'payments.main',
                  path: '#',
                  icon: ICONS.payments,
                  children: [
                    {
                      title: 'payments.pix',
                      path: PATH_DASHBOARD.payments.pix,
                      icon: ICONS.pix,
                      permission: 'payments:list',
                    },
                    {
                      title: 'payments.paymentLink.main',
                      path: PATH_DASHBOARD.payments.paymentLink,
                      icon: ICONS.paymentLink,
                      permission: 'checkouts:list',
                    },
                  ],
                },
                {
                  title: 'extract.main',
                  path: PATH_DASHBOARD.extracts.list,
                  icon: ICONS.blank,
                  permission: 'extracts:list',
                },
                {
                  title: 'bankAccounts.main',
                  path: PATH_DASHBOARD.bankAccounts.list,
                  icon: ICONS.banking,
                  permission: 'bank-accounts:list',
                },
                {
                  title: 'splits.title',
                  path: PATH_DASHBOARD.splits.list,
                  icon: ICONS.menuItem,
                  permission: 'partner-splits:list',
                },
              ],
            },
            // MANAGEMENT
            // ----------------------------------------------------------------------
            {
              subheader: 'management',
              items: [
                { icon: ICONS.user, title: 'settings', path: PATH_DASHBOARD.configs.root },
                {
                  title: 'integrations.main',
                  path: '#',
                  icon: ICONS.integrations,
                  children: [
                    {
                      title: 'integrations.apps',
                      path: PATH_DASHBOARD.integrations.list,
                      icon: ICONS.integrationApps,
                      permission: 'integrations:providers',
                    },
                    {
                      title: 'integrations.token.main',
                      path: PATH_DASHBOARD.integrations.tokens,
                      icon: ICONS.integrationTokens,
                      permission: 'tokens:list',
                    },
                    {
                      title: 'integrations.webhooks.main',
                      path: PATH_DASHBOARD.integrations.webhooks,
                      icon: ICONS.integrationWebhooks,
                      permission: 'webhooks:list',
                    },
                    //
                    // Removido até que o BACK esteja pronto
                    // {
                    //   title: 'integrations.gateways.main',
                    //   path: PATH_DASHBOARD.integrations.gateways,
                    //   icon: ICONS.gateways,
                    // },
                  ],
                },
              ],
            },
            {
              subheader: 'others',
              items: [
                {
                  title: 'support.main',
                  caption: 'support.caption',
                  path: '#',
                  icon: ICONS.chat,
                  children: [
                    // eslint-disable-next-line no-undef
                    { title: 'support.openChat', path: '#', action: () => handleOpenChat() },
                    { title: whitelabel?.email, path: '#' },
                  ],
                },
              ],
            },
          ]
        : [],
    [
      MS_EVENTZ_ENABLED,
      MS_MEMBERS_ENABLED,
      handleOpenChat,
      isEventzLoading,
      isMembershipLoading,
      openEventz,
      openMembership,
      partner?.id,
      whitelabel?.email,
    ]
  )

  console.log(addPermissionsToParent([...adminRoutes, ...partnerRoutes]))

  return useMemo(
    () => addPermissionsToParent([...adminRoutes, ...partnerRoutes]),
    [adminRoutes, partnerRoutes]
  )
}
