import { useEffect, useMemo, useState } from 'react'
import { useLocales } from '../locales'

export default function useConfigs() {
  const [configs, setConfigs] = useState({})
  const { translate } = useLocales()

  const mockEnumConfigs = useMemo(
    () => ({
      fees: {
        enum: {
          debitInstallmentKey: 0,
          pixInstallmentIndex: 13,
          pixInstallmentKey: 1000,
          firstInstallmentKey: 1,
          lastInstallmentKey: 12,
        },
      },
      anticipationReleaseDays: {
        enum: {
          immediate: 0,
          daily: 1,
          twoDays: 2,
          weekly: 7,
          biweekly: 15,
          monthly: 30,
          quarantine: 40,
          fortyFive: 45,
        },
        labels: {
          0: 'Imediata',
          1: 'Diária',
          2: 'Dois dias',
          7: 'Sete dias',
          15: 'Quinze dias',
          30: 'Mensal',
          40: 'Quarenta Dias',
          45: 'Quarenta e cinco dias',
        },
      },
      releaseStatus: {
        enum: {
          accredited: 'accredited',
          canceled: 'canceled',
          chargeback: 'chargeback',
          pending: 'pending',
        },
        labels: {
          accredited: 'Aprovado',
          canceled: 'Cancelado',
          chargeback: 'Estornado',
          pending: 'Pendente',
        },
      },
      deviceStatus: {
        enum: {
          available: 'available',
          active: 'active',
        },
        labels: {
          available: 'Disponiível',
          active: 'Ativa',
        },
      },
      paymentMethods: {
        enum: {
          creditCard: 'credit-card',
          pix: 'pix',
        },
        labels: {
          'credit-card': 'Crédito',
          pix: 'Pix',
          slip: 'Boleto',
        },
      },
      receiptTypes: {
        enum: {
          pix: 2,
          creditCard: 0,
          debitCard: 1,
        },
        labels: {
          2: translate('receipts.details.pix'),
          0: translate('receipts.details.credit'),
          1: translate('receipts.details.debit'),
        },
        icons: {
          0: 'ph:credit-card-fill',
          1: 'ph:credit-card-fill',
          2: 'ph:diamonds-four-fill',
        },
      },
      devices: {
        enum: {
          active: 'active',
          available: 'available',
          inactive: 'inactive',
        },
        labels: {
          active: translate('devices.active'),
          available: translate('devices.available'),
          inactive: translate('devices.inactive'),
        },
      },
      checkoutStatus: {
        enum: {
          pending: 'pending',
          processing: 'processing',
          paid: 'paid',
          canceled: 'canceled',
        },
        labels: {
          pending: translate('checkoutStatus.pending'),
          processing: translate('checkoutStatus.processing'),
          paid: translate('checkoutStatus.paid'),
          canceled: translate('checkoutStatus.canceled'),
        },
      },
      checkoutItemTypes: {
        enum: {
          offer: 'offer',
          bump: 'bump',
          product: 'product',
        },
      },
      webhookTypes: {
        enum: {
          receipt: 'receipt',
          transfer: 'transfer',
          payment: 'payment',
          checkout: 'checkout',
          partner: 'partner',
        },
        labels: {
          receipt: 'Recebimentos',
          transfer: 'Transferências',
          payment: 'Pagamentos',
          checkout: 'Pedidos (Checkout)',
        },
      },
      planTypes: {
        enum: {
          device: 'device',
          default: 'default',
        },
        labels: {
          device: translate('plans.types.device'),
          default: translate('plans.types.default'),
        },
      },
      chargesTypes: {
        enum: {
          boleto: 'boleto',
          pix: 'pix',
        },
        labels: {
          boleto: 'Boleto',
          pix: 'Pix',
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    setConfigs(mockEnumConfigs)
  }, [mockEnumConfigs])

  return { configs }
}
