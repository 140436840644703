import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { QRCode } from 'react-qrcode-logo'
import Render from '../../components/conditional/Render'
import Iconify from '../../components/iconify'
import useCopyToClipboard from '../../hooks/useCopyToClipboard'
import { useLocales } from '../../locales'
import { CloseIcon } from '../../theme/overrides/CustomIcons'
import axios from '../../utils/axios'
import { getResponseError } from '../../utils/laravel'

const DepositFormModal = forwardRef(({ onClose = () => {} }, ref) => {
  const [open, setOpen] = useState(false)
  const [deposit, setDeposit] = useState()
  const [isLoading, setLoading] = useState(false)
  const depositModal = useRef()
  const { copy } = useCopyToClipboard()
  const { translate } = useLocales()
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  const handleDeposit = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios.get('/api/payments/deposit')
      setDeposit(response.data)
    } catch (error) {
      enqueueSnackbar(getResponseError(error), {
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [enqueueSnackbar])

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true)
      handleDeposit()
    },
    close: handleClose,
  }))

  const copyText = useCallback(
    (text) => {
      if (text) {
        enqueueSnackbar('Copiado!')
        copy(text)
      }
    },
    [enqueueSnackbar, copy]
  )

  return (
    <Dialog ref={depositModal} open={open} onClose={handleClose} maxWidth="sm" scroll="body">
      <DialogTitle sx={{ textAlign: 'center' }}>
        <Typography variant="h4">{translate('payments.newDeposit')}</Typography>
        <Typography variant="body2">{translate('payments.readDeposit')}</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <Render if={isLoading}>
            <Skeleton variant="rectangular" width="100%" height={320} sx={{ borderRadius: 2 }} />
          </Render>

          <Render if={!isLoading}>
            <QRCode
              value={deposit?.payment || ''}
              size={200}
              ecLevel="L"
              eyeRadius={2}
              logoPaddingStyle="circle"
            />

            <TextField
              fullWidth
              value={deposit?.payment || ''}
              sx={{ mt: 5 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Copiar">
                      <Button color="primary" onClick={() => copyText(deposit.payment)}>
                        <Iconify icon="eva:copy-fill" width={24} />
                        {translate('payments.copy')}
                      </Button>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Render>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" size="small" color="inherit" onClick={handleClose}>
          {translate('payments.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

DepositFormModal.propTypes = {
  onClose: PropTypes.func,
}

export default DepositFormModal
