import { useTranslation } from 'react-i18next'
import localStorageAvailable from '../utils/localStorageAvailable'
import { useSettingsContext } from '../components/settings'
import { allLangs, defaultLang } from './config-lang'

export default function useLocales() {
  const { i18n, t: translate } = useTranslation()

  const { onChangeDirectionByLang } = useSettingsContext()

  const storageAvailable = localStorageAvailable()

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : ''

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang

  const handleChangeLanguage = (newlang) => {
    i18n.changeLanguage(newlang)
    onChangeDirectionByLang(newlang)
  }

  return {
    translate: (text, options) => translate(text, options),
    onChangeLang: handleChangeLanguage,
    currentLang,
    allLangs,
  }
}
