import PropTypes from 'prop-types'
import { createContext, useEffect, useContext, useMemo, useCallback, useState } from 'react'
import useLocalStorage from '../../hooks/useLocalStorage'
import localStorageAvailable from '../../utils/localStorageAvailable'
import { defaultSettings } from './config-setting'
import { presetsOption } from './presets'

const initialState = {
  ...defaultSettings,
  // Mode
  onToggleMode: () => {},
  onChangeMode: () => {},
  onUpdatePresetsColor: () => {},
  // Direction
  onToggleDirection: () => {},
  onChangeDirection: () => {},
  onChangeDirectionByLang: () => {},
  // Layout
  onToggleLayout: () => {},
  onChangeLayout: () => {},
  // Contrast
  onToggleContrast: () => {},
  onChangeContrast: () => {},
  // Color
  onChangeColorPresets: () => {},
  presetsOption: [],
  // Stretch
  onToggleStretch: () => {},
  // Reset
  onResetSetting: () => {},
}

export const SettingsContext = createContext(initialState)

export const useSettingsContext = () => {
  const context = useContext(SettingsContext)

  if (!context) throw new Error('useSettingsContext must be used inside SettingsProvider')

  return context
}

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
}

export function SettingsProvider({ children }) {
  const [settings, setSettings] = useLocalStorage('settings', defaultSettings)

  const [openDrawer, setOpenDrawer] = useState(false)

  const storageAvailable = localStorageAvailable()

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : ''

  const isArabic = langStorage === 'ar'

  const presetsColor = settings.presetsColor ?? defaultSettings.presetsColor

  const onToggleDrawer = useCallback(() => {
    setOpenDrawer((prev) => !prev)
  }, [])

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  useEffect(() => {
    if (isArabic) {
      onChangeDirectionByLang('ar')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArabic])

  // Mode
  const onToggleMode = useCallback(() => {
    setSettings((state) => ({
      ...state,
      themeMode: state.themeMode === 'light' ? 'dark' : 'light',
    }))
  }, [setSettings])

  const setThemeMode = useCallback(
    (mode) => {
      setSettings((state) => ({ ...state, themeMode: mode }))
    },
    [setSettings]
  )

  const onChangeMode = useCallback(
    (event) => {
      setSettings((state) => ({ ...state, themeMode: event.target.value }))
    },
    [setSettings]
  )

  const onUpdatePresetsColor = useCallback(
    (preset) => {
      setSettings((state) => ({ ...state, presetsColor: preset }))
    },
    [setSettings]
  )

  // Direction
  const onToggleDirection = useCallback(() => {
    setSettings((state) => ({
      ...state,
      themeDirection: state.themeDirection === 'rtl' ? 'ltr' : 'rtl',
    }))
  }, [setSettings])

  const onChangeDirection = useCallback(
    (event) => {
      setSettings((state) => ({ ...state, themeDirection: event.target.value }))
    },
    [setSettings]
  )

  const onChangeDirectionByLang = useCallback(
    (lang) => {
      setSettings((state) => ({ ...state, themeDirection: lang === 'ar' ? 'rtl' : 'ltr' }))
    },
    [setSettings]
  )

  // Layout
  const onToggleLayout = useCallback(() => {
    setSettings((state) => ({
      ...state,
      themeLayout: state.themeLayout === 'vertical' ? 'mini' : 'vertical',
    }))
  }, [setSettings])

  const onChangeLayout = useCallback(
    (event) => {
      setSettings((state) => ({ ...state, themeLayout: event.target.value }))
    },
    [setSettings]
  )

  // Contrast
  const onToggleContrast = useCallback(() => {
    setSettings((state) => ({
      ...state,
      themeContrast: state.themeContrast === 'default' ? 'bold' : 'default',
    }))
  }, [setSettings])

  const onChangeContrast = useCallback(
    (event) => {
      setSettings((state) => ({ ...state, themeContrast: event.target.value }))
    },
    [setSettings]
  )

  // Color
  const onChangeColorPresets = useCallback(
    (event) => {
      setSettings((state) => ({ ...state, themeColorPresets: event.target.value }))
    },
    [setSettings]
  )

  // Stretch
  const onToggleStretch = useCallback(() => {
    setSettings((state) => ({ ...state, themeStretch: !state.themeStretch }))
  }, [setSettings])

  // Reset
  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings)
  }, [setSettings])

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      // Mode
      onToggleMode,
      onChangeMode,
      setThemeMode,
      onUpdatePresetsColor,
      // Direction
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      onToggleLayout,
      onChangeLayout,
      // Contrast
      onChangeContrast,
      onToggleContrast,
      // Stretch
      onToggleStretch,
      // Color
      onChangeColorPresets,
      presetsOption,
      presetsColor,
      // Reset
      onResetSetting,
      open: openDrawer,
      onToggle: onToggleDrawer,
      onClose: onCloseDrawer,
    }),
    [
      settings,
      // Mode
      onToggleMode,
      onChangeMode,
      setThemeMode,
      onUpdatePresetsColor,
      // Direction
      onToggleDirection,
      onChangeDirection,
      onChangeDirectionByLang,
      // Layout
      onToggleLayout,
      onChangeLayout,
      onChangeContrast,
      // Contrast
      onToggleContrast,
      // Stretch
      onToggleStretch,
      // Color
      onChangeColorPresets,
      // Reset
      onResetSetting,
      presetsColor,
      openDrawer,
      onCloseDrawer,
      onToggleDrawer,
    ]
  )

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>
}
