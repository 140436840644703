// ----------------------------------------------------------------------

import { MEMBERSHIP } from '../config-global'

function path(root, sublink) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/dashboard'
const ROOTS_PASSWORD = ''
export const ROOTS_LANDING_PAGE = process.env.REACT_APP_LANDING_PAGE_URL

export const PATH_AFTER_LOGIN = path(ROOTS_DASHBOARD, '/app')
export const WHITELABEL_PATH_AFTER_LOGIN = path(ROOTS_DASHBOARD, '/reports/dashboard')
export const WHITELABEL_PATH_AFTER_LOGIN_FALLBACK = path(ROOTS_DASHBOARD, '/partners/list')

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  verify: path(ROOTS_AUTH, '/verify'),
}

export const PATH_PASSWORD = {
  root: ROOTS_PASSWORD,
  resetPassword: path(ROOTS_PASSWORD, '/forgot-password'),
  newPassword: path(ROOTS_PASSWORD, '/reset-password'),
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  configs: {
    root: path(ROOTS_DASHBOARD, '/configs'),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    list: path(ROOTS_DASHBOARD, '/products/list'),
    checkout: path(ROOTS_DASHBOARD, '/products/checkout'),
    new: path(ROOTS_DASHBOARD, '/products/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/products/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/products/${id}/edit`),
  },
  extracts: {
    root: path(ROOTS_DASHBOARD, '/extracts'),
    list: path(ROOTS_DASHBOARD, '/extracts/list'),
  },
  offers: {
    root: path(ROOTS_DASHBOARD, '/offers'),
    list: path(ROOTS_DASHBOARD, '/offers/list'),
    new: path(ROOTS_DASHBOARD, '/offers/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `/offers/${id}/edit`),
    view: (id) => path(ROOTS_DASHBOARD, `/offers/${id}`),
  },
  splits: {
    root: path(ROOTS_DASHBOARD, '/splits'),
    new: path(ROOTS_DASHBOARD, '/splits/new'),
    list: path(ROOTS_DASHBOARD, '/splits/list'),
  },
  payment: {
    view: (id) => path(ROOTS_DASHBOARD, `/payment/${id}`),
  },
  bankAccounts: {
    root: path(ROOTS_DASHBOARD, '/bank-accounts'),
    list: path(ROOTS_DASHBOARD, '/bank-accounts/list'),
  },
  transfers: {
    root: path(ROOTS_DASHBOARD, '/transfers'),
    list: path(ROOTS_DASHBOARD, '/transfers/list'),
  },
  plans: {
    root: path(ROOTS_DASHBOARD, '/plans'),
    list: path(ROOTS_DASHBOARD, '/plans/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/plans/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/plans/${id}/edit`),
    new: path(ROOTS_DASHBOARD, '/plans/new'),
  },
  receipts: {
    root: path(ROOTS_DASHBOARD, '/receipts'),
    list: path(ROOTS_DASHBOARD, '/receipts/list'),
    releases: {
      list: path(ROOTS_DASHBOARD, '/receipts/releases/list'),
    },
    orders: {
      list: path(ROOTS_DASHBOARD, '/receipts/orders/list'),
      view: (id) => path(ROOTS_DASHBOARD, `/receipts/orders/${id}`),
    },
  },
  partners: {
    list: path(ROOTS_DASHBOARD, '/partners/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/partners/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/partners/${id}/edit`),
  },
  integrations: {
    list: path(ROOTS_DASHBOARD, '/integrations/list'),
    view: (id) => path(ROOTS_DASHBOARD, `/integrations/${id}`),
    tokens: path(ROOTS_DASHBOARD, '/integrations/tokens'),
    webhooks: path(ROOTS_DASHBOARD, '/integrations/webhooks'),
    gateways: path(ROOTS_DASHBOARD, '/integrations/gateways'),
    gatewayDetails: (id) => path(ROOTS_DASHBOARD, `/integrations/gateways/${id}`),
  },
  devices: {
    list: path(ROOTS_DASHBOARD, '/devices/list'),
  },
  payments: {
    root: path(ROOTS_DASHBOARD, '/payments'),
    pix: path(ROOTS_DASHBOARD, '/payments/pix'),
    paymentLink: path(ROOTS_DASHBOARD, '/payments/payment-link'),
  },
  reports: {
    dashboard: path(ROOTS_DASHBOARD, '/reports/dashboard'),
    receipts: path(ROOTS_DASHBOARD, '/reports/receipts'),
    transfers: path(ROOTS_DASHBOARD, '/reports/transfers'),
  },
  charges: {
    root: path(ROOTS_DASHBOARD, '/charges'),
    list: path(ROOTS_DASHBOARD, '/charges/list'),
    form: path(ROOTS_DASHBOARD, '/charges/form'),
    voucher: (id) => path(ROOTS_DASHBOARD, `/charges/${id}`),
  },
}

export const PATH_MEMBERSHIP = {
  baseUrl: MEMBERSHIP.APP_URL,
  auth: {
    impersonate: '/auth/jwt/token',
    impersonateUrl: `${MEMBERSHIP.APP_URL}/auth/jwt/token`,
  },
  dashboard: {
    root: '/dashboard',
    clubs: {
      list: '/dashboard/clubs',
    },
    plans: {
      list: '/dashboard/plans',
    },
  },
}

export const PATH_DOCS = {
  root: 'https://docs.minimals.cc',
  changelog: 'https://docs.minimals.cc/changelog',
}

export const PATH_ZONE_ON_STORE = 'https://mui.com/store/items/zone-landing-page/'

export const PATH_MINIMAL_ON_STORE = 'https://mui.com/store/items/minimal-dashboard/'

export const PATH_FREE_VERSION = 'https://mui.com/store/items/minimal-dashboard-free/'

export const PATH_FIGMA_PREVIEW =
  'https://www.figma.com/file/rWMDOkMZYw2VpTdNuBBCvN/%5BPreview%5D-Minimal-Web.26.11.22?node-id=0%3A1&t=ya2mDFiuhTXXLLF1-1'
