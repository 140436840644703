import * as React from 'react'
import PropTypes from 'prop-types'
import { IMaskInput } from 'react-imask'

function MaskedInput({ onChange, name, unmask = true, ...other }, ref) {
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      unmask={unmask}
      onAccept={(value) => onChange({ target: { name, value } })}
      overwrite
    />
  )
}

MaskedInput.propTypes = {
  ...IMaskInput.propTypes,
  unmask: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default React.forwardRef(MaskedInput)
