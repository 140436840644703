import { merge } from 'lodash'
import PropTypes from 'prop-types'
import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Alert } from '../components/alert'

const AlertContext = createContext({})

const useAlert = () => {
  const context = useContext(AlertContext)

  if (!context) throw new Error('useAlert must be use inside AlertProvider')

  return context
}

const defaultAlertOptions = {
  title: '',
  message: '',
}

function AlertProvider({ children }) {
  const [alertOptions, setAlertOptions] = useState({
    ...defaultAlertOptions,
  })
  const alertRef = useRef({
    open: () => {},
    close: () => {},
  })

  const open = useCallback((props) => {
    setAlertOptions(merge({}, defaultAlertOptions, props))
    alertRef.current.open()
  }, [])

  const close = useCallback(() => {
    alertRef.current.close()
  }, [])

  const values = useMemo(
    () => ({
      open,
      close,
    }),
    [close, open]
  )

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AlertContext.Provider value={{ ...values }}>
      {children}
      <Alert {...alertOptions} ref={alertRef} />
    </AlertContext.Provider>
  )
}

AlertProvider.propTypes = {
  children: PropTypes.node,
}

export { AlertProvider, useAlert }
