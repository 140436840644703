import * as React from 'react'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { LoadingButton } from '@mui/lab'
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  DialogContent,
  Card,
  Skeleton,
} from '@mui/material'
import axios from '../../../utils/axios'
import { useLocales } from '../../../locales'
import PartnerCard from '../../../components/partnercard'
import { useSnackbar } from '../../../components/snackbar'
import { PATH_DASHBOARD } from '../../../routes/paths'
import { useAuthContext } from '../../../auth/useAuthContext'
import Render from '../../../components/conditional/Render'

const ImpersonateFormModal = forwardRef(({ onClose = () => {}, ...props }, ref) => {
  const [open, setOpen] = useState(false)
  const { translate } = useLocales()
  const [isLoading, setIsLoading] = useState(true)
  const modalConfirmation = useRef()
  const [partners, setPartners] = useState([])
  const [selectedPartner, setSelectedPartner] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { impersonate } = useAuthContext()

  const handleOpen = () => {
    onOpen()
    setOpen(true)
  }

  const handleImpersonate = React.useCallback(async () => {
    try {
      await impersonate(selectedPartner?.id)
      navigate(PATH_DASHBOARD.general.app)
      document.location.reload(true)
    } catch (error) {
      console.error(error)
      enqueueSnackbar(translate('impersonate.problemToImpersonate'), { variant: 'error' })
    }
  }, [enqueueSnackbar, navigate, selectedPartner, impersonate, translate])

  const onOpen = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await axios.get('/api/partners?paginate=false')
      setPartners(data)
    } catch (e) {
      console.warn(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const handleClose = React.useCallback(() => {
    onClose()
    setOpen(false)
  }, [onClose])

  const onChangePartner = (event, newValue) => {
    setSelectedPartner(partners?.find((acc) => acc.id === newValue.id))
  }

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }))

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      ref={modalConfirmation}
      open={open}
      onOpen={onOpen}
      onClose={handleClose}
    >
      <Render if={isLoading}>
        <Card sx={{ height: 400, justifyContent: 'center' }}>
          <Skeleton
            variant="rectangular"
            width="100%"
            sx={{ paddingTop: '100%', borderRadius: 2 }}
          />
        </Card>
      </Render>

      <Render if={!isLoading}>
        <DialogTitle> {translate('impersonate.main')} </DialogTitle>

        <DialogContent>
          <Grid item xs={12} md={8} sx={{ mt: 1 }}>
            <PartnerCard partners={partners} onChangePartner={onChangePartner} />
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{ lineHeight: 3 }}
            variant="outlined"
            size="large"
            color="inherit"
            onClick={handleClose}
          >
            {translate('impersonate.cancel')}
          </Button>

          <LoadingButton
            sx={{ lineHeight: 3 }}
            size="large"
            variant="contained"
            onClick={handleImpersonate}
          >
            {translate('impersonate.main')}
          </LoadingButton>
        </DialogActions>
      </Render>
    </Dialog>
  )
})

ImpersonateFormModal.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
}

export default ImpersonateFormModal
