// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ptBR = {
  actions: {
    accept: 'Aceitar',
    delete: 'Excluir',
    restore: 'Restaurar',
    edit: 'Editar',
    save: 'Salvar',
    cancel: 'Cancelar',
    clear: 'Limpar',
    close: 'Fechar',
    search: 'Pesquisar',
    back: 'Voltar',
    create: 'Criar',
    confirm: 'Confirmar',
    warning: 'Aviso',
    copy: 'Copiar',
    change: 'Alterar',
  },
  login: {
    main: 'Fazer Login',
    welcome: 'Bem vindo (a)!',
    newAccount: 'Novo(a) por aqui?',
    createAccount: 'Cadastre-se',
    register: 'Cadastre-se',
    forgotPassword: 'Esqueci a senha',
    loginError: 'Erro ao tentar fazer login',
    signin: 'Entrar',
    password: 'Senha',
  },
  validators: {
    nameIsRequired: 'Nome é obrigatório',
    emailIsRequired: 'Email é obrigatório',
    shouldBeValidEmail: 'Deve ser um email válido',
    shouldBeValidUrl: 'Informe uma url válida',
    passwordIsRequired: 'Senha é obrigatória',
    phoneIsRequired: 'Telefone é obrigatório',
    documentIsRequired: 'Documento é obrigatório',
    shouldBeMoreThan: 'O valor deve ser maior ou igual a',
    productTitleIsRequired: 'O título de produto é obrigatório',
    titleIsRequired: 'O título é obrigatório',
    priceIsRequired: 'O valor é obrigatório',
    originalPriceMustBeGreaterThanPrice: 'Deve ser maior que o valor',
    descriptionIsRequired: 'A descrição é obrigatória',
    mustBeLessThan: 'O valor deve ser menor ou igual ao seu total',
    recaptchaIsRequired: 'Recaptcha é obrigatório',
    fieldRequired: 'Campo obrigatório',
    percentageIsRequired: 'Percentual é obrigatório',
    linkIsRequired: 'Link é obrigatório',
    typeRequired: 'Tipo obrigatório',
    feeRequired: 'Taxa de pix é obrigatório',
    installmentRequired: 'Taxa de parcelamento é obrigatório',
    paymentTypeIsRequired: 'Tipo de pagamento é obrigatório',
    cardExpidationIsRequired: 'Expiração do cartão é obrigatória',
    cardNumberIsRequired: 'Número do cartão é obrigatória',
    cardholderNameIsRequired: 'Nome no cartão é obrigatório',
    securityCodeIsRequired: 'Código de segurança é obrigatório',
    installmentsIsRequired: 'Selecione o número de parcelas',
    invalidCardData: 'Verifique os dados do cartão e tente novamente',
    zipCodeIsRequired: 'CEP é obrigatório',
    zipCodeInvalid: 'Verfique o CEP',
    streetIsRequired: 'Nome da rua é obrigatório',
    houseNumberIsRequired: 'Número da residência é obrigatório',
    districtIsRequired: 'Bairro é obrigatório',
    stateIdIsRequired: 'Estado é obrigatório',
    cityIdIsRequired: 'Cidade é obrigatória',
    selectAtLeastOnePaymentMethod: 'Selecione ao menos um método de pagamento',
  },
  changePartner: {
    title: 'Alterar empresa',
    content: 'Deseja alterar para a empresa',
  },
  dataGridRemote: {
    loading: 'Carregando...',
    none: 'Nenhum registro',
    tryAgainInAFewMoments:
      'Não foi possível listar os dados. \n Tente novamente em alguns instantes.',
  },
  backoffice: 'Administração',
  emptyContent: 'Nenhum Registro',
  id: 'ID',
  search: 'Pesquisar',
  name: 'Nome',
  document: 'Documento',
  email: 'Email',
  phone: 'Telefone',
  day: 'Dia',
  days: 'Dias',
  type: 'Tipo',
  image: 'Imagem',
  warrantyDays: 'Dias de garantia',
  warranty: 'Garantia',
  info: 'Informação',
  infos: 'Informações',
  copy: 'Copiar',
  copied: 'Copiado!',
  delete: 'Excluir',
  paste: 'Colar',
  continue: 'Continuar',
  refresh: 'Atualizar',
  back: 'Voltar',
  inactivate: 'Desativar',
  activate: 'Ativar',
  active: 'Ativo',
  inactive: 'Inativo',
  price: 'Preço',
  title: 'Título',
  settings: 'Configurações',
  balance: 'Saldo',
  available_balance: 'Saldo disponível',
  balance_to_receive: 'Próximas liberações',
  lastReceipts: 'Últimas vendas',
  balance_blocked: 'Reservado',
  balances: 'Saldos',
  card: 'Cartão',
  pix: 'Pix',
  tax: 'Taxa',
  receipts: {
    main: 'Recebimentos',
    menu: 'Vendas',
    amount: 'Valor',
    netAmount: 'Valor líquido',
    accredited: 'Aprovado',
    canceled: 'Cancelado',
    chargeback: 'Estornado',
    summary: 'Resumo',
    created_at: 'Gerado em',
    details: {
      title: 'Detalhes',
      debit: 'Débito',
      credit: 'Crédito',
      pix: 'Pix',
      transaction: 'Código de transação',
      transactionDate: 'Data da transação',
      status: 'Status',
      cardNumber: 'Nº do cartão',
      antecipationTax: 'Taxa de antecipação',
      tax: 'Taxa',
      fixedFeeAmount: 'Taxa por venda',
      type: 'Tipo',
      plotsQuantity: 'Parcelas',
      release: {
        title: 'Lançamentos',
        releaseDate: 'Data de liberação',
        scheduledDate: 'Previsão de liberação',
        reserved: 'Reserva de Saldo',
        receipt: 'Liberação',
        details: 'Detalhes da Venda',
      },
      children: {
        title: 'Splits',
        partnership: 'Split',
        affiliation: 'Afiliação',
        partner: 'Parceiro',
      },
      parent: {
        title: 'Venda de origem',
      },
    },
  },
  reports: {
    main: 'Relatórios',
    receipts: 'Vendas',
    transfers: 'Tranferências',
  },
  checkout: {
    title: 'Checkout',
    purchaseDetails: 'Dados da compra',
    paymentMethod: 'Forma de pagamento',
    extras: 'Adicione ao pedido',
    expirationDate: 'Prazo de pagamento',
    discounts: 'Descontos',
    platformFee: 'Taxa da plataforma',
    gatewayFee: 'Taxa do {{method}}',
    subtotal: 'Subtotal',
    total: 'Total',
    confirmCheckout: 'Finalizar pedido',
    practicalAndFast: 'Prático e rápido',
    qrCodeMessage: 'Pagamento instantâneo e sem complicações.',
    creditCardMessage: 'Pague do seu jeito, com segurança e praticidade.',
    buyNow: 'COMPRAR AGORA',
    paymentMethodChargedFee:
      '* A forma de pagamento selecionada contém acréscimo de juros e/ou taxas de serviço',
    termsOfPurchaseMessage:
      'Ao comprar, eu concordo (i) que a {{whitelabel}} está processando este pedido; (ii) com os Termos de Compra (iii) que li e estou ciente da Política de Privacidade e (iv) que sou maior de idade ou autorizado e acompanhado por um tutor legal.',
    fullName: 'Nome completo',
    email: 'Email',
    for: 'Por',
    phone: 'Telefone',
    payerDocument: 'CPF/CNPJ do titular',
    offer: 'OFERTA EXCLUSIVA',
    bumpText: 'Sim, quero adicionar essa oferta em minha compra',
    creditCard: 'Cartão de crédito',
    payWithPix: 'Pix',
    nameOnCard: 'Titular do cartão',
    cardNumber: 'Número do cartão',
    monthAndYear: 'Mês/Ano',
    installments: 'Em quantas vezes?',
    interestFree: '*',
    installmentsDescription: '{{installment}}x de {{amount}} {{interestFree}}',
    pixTotal: '{{amount}} {{interestFree}}',
    cvv: 'CVV',
    modalTitle: 'Link expirado',
    modalCaption: 'O tempo para realizar este pagamento acabou!',
    address: 'Endereço de cobrança',
    paymentSuccess: 'Pagamento efetuado',
    checkoutConfirmation: 'Confirmação da compra',
    subscription: 'Assinatura',
    subscriptionValue: '{{amount}}/mês',
    subscriptionDescription: 'Cobrada mensalmente',
    status: {
      pending: 'Pendente',
      processing: 'Aguardando Pagamento',
      paid: 'Pago',
      canceled: 'Cancelado',
    },
    statusPage: {
      total: 'Valor',
      paymentMethod: 'Forma de pagamento',
      paymentInfo: 'Informações do pagamento',
      company: 'Empresa',
      support: 'Suporte',
      date: 'Data',
      identifier: 'Identificador',
      customerData: 'Dados do comprador',
      customer: 'Cliente',
      contact: 'Contato',
      checkoutResume: 'Resumo do checkout',
      payWithPix: 'Pague com Pix',
      pixDisclaimer:
        'Quando o pagamento for aprovado, você receberá uma confirmação por email. Não se esqueça de verificar sua pasta de spam.',
      pixInstructionTitle: 'Realize o pagamento',
      pixInstructionDescription:
        'Copie o código abaixo e utilize o Pix Copia e Cola no aplicativo \n que você vai fazer o pagamento',
      pixInstruction1: 'Abra o aplicativo do seu banco e acesse a área Pix',
      pixInstruction2:
        'Selecione a opção Pix Copia e Cola, cole o código acima e realize o pagamento.',
      pixInstruction3: 'Aguarde a confirmação do pedido.',
      copy: 'COPIAR CÓDIGO',
    },
  },
  charge: {
    schema: {
      selectType: 'Selecione o tipo',
      pixCode: 'Pix copia e cola',
      barcode: 'Código de barras',
    },
    installmentStepTitle: 'Informações da transação',
    sendPix: 'Enviar Pix',
    payBoleto: 'Pagar Boleto',
    additionalInfo: 'Informações adicionais',
    paymentInfo: 'Forma de pagamento',
    info: 'Informações do pagamento',
  },
  checkoutStatus: {
    pending: 'Pendente',
    processing: 'Processando',
    paid: 'Pago',
    canceled: 'Cancelado',
    statusText: {
      pending: 'Aguardando pagamento...',
      processing: 'Aguardando pagamento...',
      paid: 'Pagamento aprovado',
      canceled: 'Pagamento cancelado',
    },
  },
  transfers: {
    main: 'Saques',
    new: 'Novo Saque',
    recents: 'Saques Recentes',
    viewAll: 'Ver todos',
    columns: {
      scheduleDate: 'Data',
      bankAccount: 'Conta Bancária',
      account: 'Conta',
      status: 'Status',
      value: 'Valor',
    },
    newButton: 'Novo',
    quickTransfer: {
      main: 'Saque rápido',
      bankAccount: 'Conta bancária',
      amount: 'Valor',
      confirm: 'Confirmar saque',
      balance: 'Seu saldo',
      now: 'Sacar agora',
      bankName: 'Nome do banco: ',
    },
    confirmModal: {
      to: 'Sacar para',
      alert: 'Seu saque será processado em até 24h úteis.',
      agency: 'Agência',
      account: 'Conta',
      confirm: 'Confirmar saque',
      success: 'Saque realizado!',
    },
  },
  general: {
    main: 'Geral',
    cancel: 'Cancelar',
    save: 'Salvar',
    back: 'Voltar',
    close: 'Fechar',
  },
  contract: {
    modalTitle: 'Assinar Contrato',
    modalCaption: 'É necessário assinar o contrato para realizar saques',
    modalWarning: 'Após assinar, recarregue a página.',
    signNow: 'Assinar Agora',
    signLater: 'Assinar depois',
  },
  impersonate: {
    mail: 'Email',
    main: 'Impersonar',
    problemToImpersonate: 'Problemas para impersonar',
    selectPartner: 'Selecione o parceiro',
    cancel: 'Cancelar',
    close: 'Encerrar',
    tryAgainLater: 'Tente novamente mais tarde',
  },
  canceled: 'Cancelada',
  accredited: 'Aprovada',
  chargeback: 'Estornada',
  partners: {
    main: 'Parceiros',
    edit: 'Editar Parceiro',
    editForm: {
      personalData: 'Dados Pessoais',
      anticipationTax: 'Taxa Antecipação',
      anticipationTypeLabel: 'Recebe em',
      pixAnticipationLabel: 'Antecipação do PIX',
      cardAnticipationLAbel: 'Atecipação do cartão',
      releasedDays: '{{days}} dias',
      securityReserve: 'Reserva de saldo',
      pixAnticipationTax: 'Taxa PIX',
    },
    details: {
      main: 'Detalhes do Parceiro',
      personalData: 'Dados Pessoais',
      blockedDaysToRelease: 'Dias para liberação do valor reservado',
      percentageForSecurityReserve: 'Porcentagem para reserva de segurança',
      admins: 'Administradores',
    },
    schema: {
      requiredEmail: 'Email é obrigatório',
      requiredName: 'Nome é obrigatório',
      requiredDocument: 'CPF/CPNJ é obrigatório',
      requiredPhone: 'Telefone é obrigatório',
      requiredPlan: 'Plano é obrigatório',
    },
    updatedSuccess: 'Parceiro atualizado com sucesso!',
    registrationDate: 'Data de Cadastro',
  },
  paid: 'Pago',
  pending: 'Pendente',
  listColumns: {
    active: 'Ativo',
    payer: 'Pagador',
    beneficiary: 'Beneficiário',
    intermediary: 'Intermediador',
    dueDate: 'Vencimento',
    affiliate: 'Dados do afiliado',
    auto_approve: 'Aprovação automática de afiliados',
    url: 'URL',
    main: 'Principal',
    global: 'Global',
    account: 'Conta',
    actions: 'Ações',
    agAccount: 'Ag/Conta',
    agency: 'Agência',
    address: 'Endereço',
    amount: 'Valor',
    anticipation_type: 'Tipo de antecipação do cartão',
    pix_anticipation_type: 'Tipo de antecipação PIX',
    available_balance: 'Saldo disponível',
    complement: 'Complemento',
    customer: 'Cliente',
    bankAccount: 'Conta Bancária',
    bank_account: 'Conta bancária',
    bank: 'Banco',
    city: 'Cidade',
    created_at: 'Data de criação',
    date: 'Data',
    description: 'Descrição',
    details: 'Detalhes',
    document: 'CPF/CNPJ',
    discounts: 'Desconto',
    merchant: 'Negócio',
    cnae: 'Atividade econômica (CNAE)',
    mcc: 'Categoria do Negócio (MCC)',
    district: 'Bairro',
    email: 'E-Mail',
    fee: 'Taxa',
    holder: 'Titular',
    house_number: 'Número',
    id: 'ID',
    installment: 'Parcela',
    link: 'Link',
    main_account: 'Principal',
    name: 'Nome',
    legal_name: 'Razão Social',
    net_amount: 'Líquido',
    owner: 'Produtor',
    partner_id: 'Tipo',
    productName: 'Nome do Produto',
    percentage: 'Porcentagem',
    plan: 'Plano',
    neighborhood: 'Bairro',
    phone: 'Telefone',
    price: 'Preço',
    product: 'Produto',
    sub_type: 'Tipo',
    offer: 'Oferta',
    promotional_price: 'Preço Promocional',
    receiver: 'Coprodutor',
    release_date: 'Liberação',
    resume: 'Resumo',
    sales_page: 'Página de vendas',
    schedule_date: 'Data',
    scheduled_date: 'Previsão para Liberar',
    status: 'Status',
    support_email: 'E-mail de Suporte',
    street: 'Rua',
    state: 'Estado',
    title: 'Título',
    total: 'Total',
    type: 'Tipo',
    updated_at: 'Atualizado em',
    value: 'Valor',
    warranty_days: 'Garantia',
    days: 'Dias',
    zip_code: 'CEP',
    provider: 'Integração',
    meta: 'Descrição',
    model: 'Modelo',
    serial_number: 'Número de série',
    signatureToken: 'Token de assinatura',
    partner: 'Parceiro',
    pending: 'Pendente',
    expiration: 'Validade',
    expires_at: 'Validade',
    revoked: 'Status',
    action: 'Desativar',
    total_sales: 'Faturamento',
    average_ticket: 'Ticket médio',
    fees: 'Taxas',
    costs: 'Custos',
    profit: 'Lucro',
    balance: 'Saldo',
    total_balance: 'Saldo total',
    blocked_balance: 'Transf. pendentes',
    pending_balance: 'Saldo a liberar',
    transfer_total: 'Total',
    transfer_fees: 'Taxa de transferência',
    available: 'disponível',
    to_release: 'à liberar',
    blocked: 'bloqueado',
    to_transfer: 'Transferências pendentes',
    signup_at: 'Data de Cadastro',
    key: 'Chave',
  },
  orders: {
    method: 'Método',
    actions: 'Ações',
    customerPhone: 'Telefone do Cliente',
    createdAt: 'Data',
    main: 'Pedidos',
    orderDetails: 'Detalhes do pedido',
    orders: 'Pedidos',
    total: 'Total',
    status: 'Status',
    discounts: 'Descontos',
    customer: 'Cliente',
  },
  plans: {
    main: 'Planos',
    new: 'Novo Plano',
    edit: 'Editar Plano',
    details: {
      main: 'Detalhes do Plano',
      type: 'Tipo do plano',
      percentageForSecurityReserve: 'Reserva de segurança',
      blockedDaysToRelease: 'Valor reservado é liberado em: ',
      blockedDaysToReleaseValue: '{{days}} dias',
      fixedFeeAmount: 'Taxa fixa',
      percentageFeeAmount: 'Taxa percentual',
      pixAnticipationTax: 'Taxa de Antecipação PIX',
      anticipationTax: 'Taxa de antecipação',
      publicLabel: 'Mostrar para parceiros?',
      public: 'Sim',
      private: 'Não',
      defaultPlan: 'Plano padrão',
      devicePlan: 'Plano para maquininha',
      antifraud: 'Análise antifraude aprimorada',
      reviewAlert:
        '⚠️ Vendas no cartão podem ser rejeitadas se essa opção for marcada com endereço e dados do negócio incorretos.',
    },
    types: {
      device: 'Maquininha',
      default: 'Padrão',
    },
    columns: {
      planName: 'Plano padrão',
      devicePlanName: 'Plano de maquininha',
      anticipationType: 'Tipo de Antecipação',
    },
    form: {
      newPlan: 'Novo Plano',
      editPlan: 'Editar Plano',
      createPlan: 'Criar Plano',
      fee: 'Taxa',
      posFee: 'Taxa da Maquininha',
      name: 'Nome do Plano',
      fixedFeeAmount: 'Taxa fixa',
      percentageFeeAmount: 'Taxa fixa percentual',
      anticipationType: 'Tipo de antecipação',
      anticipationTax: 'Taxa de antecipação',
      pixAnticipationType: 'Tipo da antecipação pix',
      pixAnticipationTax: 'Taxa de antecipação pix',
      bookedDaysToReleased: 'Dias para liberação da reserva',
      percentageForSecurityReserve: 'Reserva de segurança',
      publicPlanRadioLabel: 'Mostrar para parceiros?',
      public: 'Sim',
      private: 'Não',
      debit: 'Débito',
      credit: 'Crédito',
    },
    schema: {
      requiredName: 'Informe o nome',
      requiredFixedFeeAmount: 'Informe o valor da taxa fixa',
      requiredPercentageFeeAmount: 'Informe o valor da taxa percentual',
      requiredAnticipationType: 'Informe o tipo da antecipação',
      requiredAnticipationTax: 'Informe a taxa de antecipação',
      requiredPixAnticipationType: 'Informe o tipo da antecipação por pix',
      requiredPixAnticipationTax: 'Informe a taxa de antecipação do pix',
      requiredBookedDaysToReleased: 'Informe os dias para liberação do valor',
      requiredPercentageForSecurityReserve: 'Informe a porcentagem para reserva de segurança',
      requiredPlanFees: 'Campos Obrigatórios',
    },
    confirmDialog: {
      title: 'Excluir',
      content: 'Você tem certeza que deseja excluir o Plano?',
      action: 'Sim, Excluir',
    },
    messages: {
      createdSuccess: 'Plano criado com sucesso!',
      updatedSuccess: 'Plano atualizado com sucesso!',
      deletedSuccess: 'Plano excluído com sucesso',
      tryAgainLater: 'Tente novamente mais tarde',
    },
  },
  releases: {
    main: 'Liberações de Vendas',
    filter: 'Liberações',
    released: 'Liberado',
    to_release: 'Próximas liberações',
    total: 'Total em vendas',
    net_amount: 'Valor líquido / Comissões',
  },
  signup: {
    phone: 'Telefone',
    main: 'Crie sua conta',
    confirm: {
      title: 'Verifique sua conta',
      alert: 'Confira os dados para finalizar o cadastro',
      holder: 'Titular',
      document: 'CPF/CNPJ',
      agency: 'Agência',
      account: 'Conta',
      digit: 'Dígito',
      btnConfirm: 'Confirmar',
      back: 'Alterar meus dados',
    },
    personal: {
      schema: {
        birthdate: 'Data de nascimento é obrigatória!',
        password: {
          required: 'A senha é obrigatória',
          min: 'A senha deve ter no mínimo 8 caracteres',
          matches:
            'A senha deve conter pelo menos uma letra maiúscula e minúscula, um número e um caractere especial',
        },
        confirm_password: {
          required: 'Por favor, confirme sua senha',
          doesntMatch: 'As senhas não coincidem',
        },
      },
    },
    payment: {
      processing: 'O pagamento ainda não foi reconhecido, aguarde um instante.',
      verify: 'Realizei pagamento',
      bankAlert: 'O valor pago ficará disponível no seu saldo.',
      alert:
        'Pague esse Pix em uma conta bancária de sua titularidade! Este é um procedimento de segurança para confirmar a sua identidade.',
      alertTitle: 'Atenção',
    },
    finish: {
      title: 'Agora é só aguardar!',
      description:
        'Seu cadastro está em análise, você receberá um e-mail assim que o processo for concluído.',
      caption: 'A análise é automática e leva em média 15 minutos',
      login: 'Fazer login',
    },
    verify: {
      codeIsRequired: 'Código é obrigatório',
      button: 'Verificar',
      title: 'Código de verificação',
      subtitle1: 'Enviamos um código de verificação para',
      subtitle2: 'Verifique sua caixa de mensagens',
      resend: 'Solicite um novo código de confirmação em',
      requiredPhone: 'Informe o telefone',
      requiredEMail: 'E-mail obrigatório',
      invalidEMail: 'Digite um e-mail válido',
      buttonText: 'Novo código',
      errorCode: 'O código já foi enviado, aguarde pelo menos 2 minutos',
      valid: {
        code: 'Código verificado!',
        requiredCode: 'Código é obrigatório',
      },
    },
    redirect: 'Já possui conta?',
    continue: 'Continuar',
  },
  resetPassword: {
    main: 'Redefinição de senha',
    success: 'Senha redefinida',
    forgot: {
      pageTitle: 'Esqueceu sua senha',
      pageDescription: 'Digite o endereço de e-mail associado à sua conta e enviaremos a senha.',
      title: 'Redefinição de senha',
      subtitle:
        'Um link de recuperação de senha foi enviado para o seu email. Verifique sua caixa de entrada e siga as instruções para redefinir sua senha',
      buttonText: 'Voltar ao login',
    },
    newPassword: {
      confirmPass: 'Confirmar senha',
      validation: {
        requiredEMail: 'Email obrigatório',
        invalidEMail: 'Digite um e-mail válido',
        requiredPassword: 'A senha é obrigatória',
        passwordMin: 'A senha deve ter no mínimo 8 caracteres',
        passwordMatches:
          'A senha deve conter pelo menos uma letra maiúscula e minúscula, um número e um caractere especial',
        passwordMatchesError: 'As senhas não coincidem',
      },
      errorToken: 'Erro no link de redefinição da senha, token não informado, tente novamente',
    },
    resetButtonText: 'Redefinir',
    alertSuccess:
      'Recuperação enviada, siga os passos enviados para o seu e-mail para recuperar sua senha.',
  },
  splits: {
    title: 'Splits',
    newSplit: 'Novo Split',
    editSplit: 'Editar Split',
    createSplit: 'Criar Split',
    publicLabel: 'Mostrar para parceiros?',
    status: {
      pending: 'Pendente',
      accepted: 'Aceito',
      canceled: 'Cancelado',
    },
    receiverEmail: 'Email do Coprodutor (Quem vai receber)',
    accept: 'Aceitar',
    reject: 'Rejeitar',
    delete: 'Excluir',
    incoming: 'Entrada',
    incomingDescription:
      'Split de ENTRADA é quando você recebe valor ou comissão de venda de um(a) Empresa/Vendedor.',
    outgoing: 'Saída',
    outgoingDescription:
      'Split de SAÍDA é quando você envia valor/comissão de uma venda para alguém.',
    pageText:
      'Aqui você verifica todos os splits e solicitações que a sua conta compartilha e também os que a sua conta recebe.',
    deleteConfirmTitle:
      'Você tem certeza que deseja excluir o Split?\nIsso não poderé ser desfeito.',
    deleteConfirmText: 'Sim, Excluir',
    acceptConfirmText: 'Você tem certeza que deseja aceitar o split?',
    rejectConfirmText: 'Você tem certeza que deseja rejeitar o split?',
    messages: {
      notFoundEmail: 'Não foi encontrado nenhum cadastro com o email informado',
      tryAgainLater: 'Tente novamente mais tarde',
      createdSuccess: 'Split criado com sucesso!',
      updatedSuccess: 'Split atualizado com sucesso!',
      deletedSuccess: 'Split excluído com sucesso',
      acceptedSuccess: 'Split aceito com sucesso',
      rejectedSuccess: 'Split rejeitado com sucesso',
    },
  },
  partner: {
    plans: {
      yourPlan: 'Seu plano',
      changePlan: 'Alterar plano',
      planName: 'Recebimento das vendas',
      currentPlan: 'Plano atual',
      anticipationRules: 'Regras de antecipação',
      anticipationTax: 'Taxa de antecipação',
      fixedFeeAmount: 'Taxa por venda',
      anticipationType: 'Tipo de antecipação',
      pixAnticipationTax: 'Taxa de antecipação no PIX',
      pixAnticipationType: 'Tipo de antecipação no PIX',
      toReceiveDays: 'Dias para receber',
      toReceiveDaysPix: 'Dias para receber no PIX',
      anticipationWarning: '*Liberação em {{daysToRelease}} dias.',
      fullAnticipationWarning:
        '*Voce pode antecipar até {{percentageValue}}% das suas vendas. O restante será liberado em {{daysToRelease}} dias.',
      contentDialogTitle: 'Você tem certeza que deseja mudar o Plano?',
      contentDialogBody: '⚠️ A alteração do plano entra em vigor nas próximas vendas.',
      confirmChange: 'Sim, mudar',
      messages: {
        changedWithSuccess: 'Atualizado com sucesso!',
        tryAgainLater: 'Tente novamente mais tarde',
      },
      fees: {
        headerTitle: 'Taxas do plano',
      },
    },
  },
  configs: {
    main: 'Configurações',
    tabs: {
      defaultPlan: 'Plano padrão',
      devicePlan: 'Plano de maquininha',
    },
  },
  promotional_price: 'Preço promocional',
  blocked: 'Bloqueado',
  available: 'Disponível',
  to_receive: 'A receber',
  app: 'Dashboard',
  dashboard: 'Dashboard',
  user: 'Usuário',
  list: 'Lista',
  password: 'Senha',
  confirmPassword: 'Confirmar Senha',
  birthdate: 'Data de nascimento',
  edit: 'Editar',
  shop: 'Loja',
  blog: 'Blog',
  post: 'Post',
  mail: 'Email',
  chat: 'Chat',
  cards: 'Cartões',
  posts: 'Posts',
  create: 'Criar',
  kanban: 'Kanban',
  banking: 'Financeiro',
  booking: 'Reserva',
  profile: 'Perfil',
  account: 'Conta',
  others: 'Outros',
  affiliations: 'Afiliações',
  affiliates: 'Afiliados',
  code: 'Código',
  affiliation: {
    main: 'Afiliação',
    percentage: 'Comissão de',
    affiliate: 'Afiliar',
    productType: 'Tipo de produto',
    aboutPartner: 'Sobre o produtor',
    since: 'Desde',
    email: 'Suporte',
    types: {
      course: 'Curso',
      ebook: 'Ebook',
      others: 'Outros',
    },
    messages: {
      createdAffiliation: 'Afiliação criada com sucesso',
      requestedAffiliation: 'Solicitação de afiliação enviada',
      tryAgainLater: 'Tente novamente mais tarde',
    },
  },
  offer: {
    createOffer: 'Criar oferta',
    newOffer: 'Nova oferta',
    editOffer: 'Editar oferta',
    main: 'Oferta',
    filter: 'Ofertas',
    OrdersBumps: 'Orders bumps',
    offers: 'Ofertas',
    priceOffers: 'Preço e Ofertas',
    offerDetails: 'Detalhes da oferta',
    searchForProduct: 'Procure por produto',
    typeProductTitle: 'Digite o título do produto',
    offerTitle: 'Título da oferta',
    confirmationPage: 'Página de obrigado / upsell (em breve)',
    feeInstallment: 'Taxa de parcelamento',
    feePix: 'Taxa de pix',
    offerClientFee: 'O cliente assume as taxas',
    offerMeFee: 'Eu assumo as taxas',
    fees: {
      main: 'Taxas',
      offerClient: 'O cliente assume as taxas',
      partner: 'Eu assumo até {{installment}}x',
      offerMe: 'Eu assumo todas as taxas',
    },
    messages: {
      successesUpdade: 'Oferta atualizada com sucesso',
      successCreate: 'Oferta criada com sucesso',
      successDelete: 'Oferta excluída com sucesso',
      tryAgainLater: 'Tente novamente mais tarde',
    },
    links: {
      main: 'Links de divulgação',
      addLink: 'Adicionar link',
      customLink: 'Link personalizado',
      copyLink: 'Copiar link',
      generateLinks: 'Gerar links',
      generateCustomLink: 'Adicionar Link',
      messages: {
        tryAgainLater: 'Tente novamente mais tarde',
        createdLinkSuccessfully: 'Link criado com sucesso',
        externalLinkSuccessfully: 'Link externo gerado com sucesso',
      },
    },
  },
  order: {
    status: 'Status',
    method: 'Forma de Pagamento',
    paymentLink: 'Link do pagamento',
    main: 'Pedidos',
    orderDetails: 'Detalhes do pedido',
    orders: 'Pedidos',
    discounts: 'Descontos',
    customer: 'Cliente',
    paid: 'Pago',
    pending: 'Pendente',
    canceled: 'Cancelado',
  },
  product: {
    main: 'Produto',
    products: 'Produtos',
    subTitle: 'Detalhes do produto',
    productsAffiliate: 'Sou Afiliado',
    productsProducer: 'Sou Produtor',
    productCreatedSucessfully: 'Produto criado com sucesso',
    all: 'Todos',
    affiliates: {
      main: 'Afiliados',
      secondary: 'Afiliação',
      modal: {
        percent: 'Percentual de comissão',
        global: 'Afiliação Global',
        autoApprove: 'Aprovação automática',
        autoApproveDescription: 'Ative para aprovar novos afiliados automaticamente',
        createRule: 'Criar Convite',
        newRule: 'Novo Convite',
        editRule: 'Editar Convite',
      },
      messages: {
        ruleCreateSuccess: 'Convite criado com sucesso',
        ruleUpdateSucess: 'Convite atualizado com sucesso',
        tryAgainLater: 'Tente novamente mais tarde',
      },
    },
    new: 'Novo',
    content: 'Conteúdo',
    upSellLink: 'Página de upsell',
    newRule: 'Novo Convite',
    yesDelete: 'Sim, Excluir',
    copyLink: 'Link de afiliação',
    details: 'Detalhes',
    makeMain: 'Tornar Padrão',
    isMainRule: 'Convite Padrão',
    deleteRuleMessage: 'Você realmente deseja excluir esse convite?',
    affiliationRules: 'Afiliação',
    email: 'Email',
    salesPage: 'Página de Vendas',
    warrantyDays: 'Dias de garantia (para estorno)',
    description: 'Descrição',
    productName: 'Nome do produto',
    deleteProductMessage: 'Você tem certeza que deseja excluir o produto',
    affiliations: {
      yesAccept: 'Sim, aceitar',
      yesCancel: 'Sim, cancelar',
      acceptAffiliation: 'Você tem certeza que deseja aceitar a afiliação?',
      cancelAffiliation: 'Você tem certeza que deseja cancelar a afiliação?',
      status: {
        blocked: 'Bloqueado',
        pending: 'Pendente',
        active: 'Ativo',
        canceled: 'Cancelado',
        inactive: 'Inativo',
      },
    },
    messages: {
      successacceptAffiliation: 'Afiliação aceita com sucesso!',
      successcancelAffiliation: 'Afiliação cancelada com sucesso!',
      affiliationRulesDeleteSuccess: 'Convite excluído com sucesso!',
      productDeletedSuccessfully: 'Produto excluído com sucesso',
      tryAgainLater: 'Tente novamente mais tarde',
      requiredPercentage: 'Percentual é obrigatório',
      productAttSuccessfully: 'Produto atualizado com sucesso',
    },
    create: {
      name: 'Nome do produto',
      link: 'Página de vendas',
      description: 'Descrição',
      warrantyDays: 'Tempo de garantia para reembolso',
      image: 'Imagem',
      status: 'Status',
      type: 'Tipo',
      createProduct: 'Criar produto',
      save: 'Salvar',
    },

    types: {
      course: 'Curso',
      ebook: 'E-book',
      other: 'Outros',
    },
    status: {
      main: 'Status',
      active: 'Ativo',
      inactive: 'Inativo',
    },
    warranty: {
      sevenDays: '7 dias',
      fifteenDays: '15 dias',
      thirtyDays: '30 dias',
    },
  },
  extract: {
    main: 'Extrato',
  },
  payments: {
    main: 'Cobranças',
    pix: 'Pix',
    new: 'Nova Cobrança',
    paidAt: 'Pago em',
    createdAt: 'Criado em',
    customerName: 'Cliente',
    document: 'Documento',
    description: 'Descrição',
    copy: 'Copiar',
    close: 'Fechar',
    newPayment: 'Nova cobrança',
    alert: 'Atenção',
    alertMessage: 'Caso o valor da cobrança seja zero, o valor será definido pelo cliente pagador.',
    cancel: 'Cancelar',
    create: 'Gerar',
    name: 'Nome',
    amount: 'Valor',
    readDeposit: 'Faça a leitura do QR Code para adicionar dinheiro na sua conta via Pix',
    newDeposit: 'Depósito',
    withdraw: 'Saque',
    paymentLink: {
      main: 'Links de pagamento',
      details: 'Detalhes',
      new: 'Novo Link',
      create: 'Criar link de pagamento',
      createSuccess: 'Link de pagamento criado com sucesso!',
      editSuccess: 'Link de pagamento editado com sucesso!',
      id: 'ID do link',
      status: 'Status',
      customer: 'Cliente',
      contact: 'Contato',
      open: 'Abrir link',
      edit: 'Editar',
      generate: 'Gerar link',
      link: 'Link do pagamento',
      expiration: 'Data de expiração',
      payment_methods: 'Métodos de pagamento',
      title: 'Título',
      description: 'Descrição',
      price: 'Valor',
      original_price: 'Valor sem desconto (opcional)',
      charge_pix_fee: {
        title: 'Repassar taxas do Pix',
        description: 'O cliente paga as taxas do Pix.',
      },
      charge_fixed_fee: {
        title: 'Repassar taxas da plataforma',
        description: 'O cliente paga as taxas da plataforma.',
      },
      allow_multiple_orders: {
        title: 'Link reutilizável',
        description: 'Receba pagamentos de diferentes pessoas no mesmo link.',
      },
      subscription: {
        title: 'Assinatura (mensal)',
        description: 'Receba pagamentos mensais de forma automática.',
      },
      pay_card_fee_installment: {
        title: 'Taxas de parcelamento',
        handle: 'Repassar todas as taxas',
        options: 'Sem juros até {{installment}}x',
      },
      configs: 'Configurações',
      success: 'Link de pagamento criado com sucesso!',
      paidPaymentDetails: 'Pagamento',
      paidPaymentDate: 'Pago em',
      paidPaymentID: 'ID do Pagamento',
      paidPaymentMethod: 'Método de pagamento',
      paidAmount: 'Total',
      paidInstallments: 'Parcelamento',
    },
  },
  bankAccounts: {
    main: 'Contas bancárias',
    new: 'Novo',
    copied: 'Copiado!',
    addBankAccount: 'Adicionar conta bancária',
    validateWithPix:
      'Realize esse pagamento via Pix para validar e cadastrar sua conta bancária em nosso sistema',
    sameOwnership:
      'Atenção - A conta que você utilizar para pagar deve possuir a mesma titularidade do cadastro.',
    paymentInstructions: 'Instruções para pagamento:',
    payWithQRCode: 'Pague com o QRCode ou Copia e Cola',
    alreadyPaid: 'Já paguei',
    deleteTheAccount: 'Você tem certeza que deseja excluir a Conta?',
    yesDelete: 'Sim, Excluir',
    yesChange: 'Sim, mudar',
    setAsPrimary: 'Usar como Principal',
    accountToThePrimaryOne: 'Deseja realmente mudar esta conta para a principal?',
    messages: {
      accountUpdatedSuccessfully: 'Conta principal atualizada com sucesso!',
      accountDeletedSuccessfully: 'Conta excluída com sucesso!',
      tryAgainLater: 'Tente novamente mais tarde',
      copied: 'Copiado!',
    },
  },
  yes: 'Sim',
  no: 'Não',
  availableBalance: 'Saldo disponível',
  extracts: 'Extrato',
  invoice: 'Fatura',
  details: 'Detalhes',
  calendar: 'Calendário',
  analytics: 'Analytics',
  ecommerce: 'Ecommerce',
  management: 'Gerenciamento',
  menu_level: 'Nível do menu',
  menu_level_2a: 'Menu nível 2a',
  menu_level_2b: 'Menu nível 2b',
  menu_level_3a: 'Menu nível 3a',
  menu_level_3b: 'Menu nível 3b',
  menu_level_4a: 'Menu nível 4a',
  menu_level_4b: 'Menu nível 4b',
  item_disabled: 'Item desabilitado',
  item_label: 'Etiqueta do item',
  item_caption: 'Legenda do item',
  item_external_link: 'Link externo do item',
  description: 'Descrição',
  other_cases: 'Outros casos',
  item_by_roles: 'Item por função',
  only_admin_can_see_this_item: 'Somente o administrador pode ver este item',
  new: 'Novo',
  doc: 'Documentação',
  filter: {
    clearFilter: 'Limpar',
    close: 'Fechar',
    fields: {
      net_amount: 'Valor líquido',
      productName: 'Produto',
      customerName: 'Cliente',
      paidAmount: 'Valor pago',
      release_date: 'Data de liberação',
      transaction_date: 'Data da transação',
      created_at: 'Data',
      title: 'Título',
      scheduled_date: 'Data a Liberar',
      status: 'Status',
      types: 'Tipo de pagamento',
      amount: 'Valor',
      name: 'Nome',
      email: 'Email',
      planName: 'Nome do plano',
      publicPlans: 'Exibir para parceiros?',
      document: 'Documento',
      serial_number: 'Número de série',
      model: 'Modelo',
      partner_name: 'Parceiro',
      payment_method: 'Forma de pagamento',
      price: 'Valor',
      promotional_price: 'Valor promocional',
      paid_at: 'Data de pagamento',
      creation_date: 'Data de criação',
    },
    schema: {
      message: 'Ambas datas devem ser informadas',
    },
    title: 'Filtrar',
  },
  integrations: {
    main: 'Integrações',
    enabled: 'Integrações Cadastradas - {{providerName}}',
    authorizationLink: 'Autorizar integração',
    apps: 'Apps',
    webhooks: {
      main: 'Webhooks',
      new: 'Novo webhook',
      details: 'Detalhes do webhook',
      created_at: 'Criado em',
      deleted_at: 'Deletado em',
      inactive: 'Desativado',
      active: 'Ativado',
      disable: 'Desativar',
      activate: 'Ativar',
      confirmDelete: 'Desabilitar webhook',
      deleteMessage: 'Deseja realmente desativar o webhook?',
      confirmMessage: 'Deseja realmente ativar o webhook?',
      deleteButton: 'Sim, desativar',
      confirmButton: 'Sim, ativar',
      confirmActive: 'Habilitar webhook',
      schema: {
        type: 'Selecione o tipo de Webhook',
        url: 'Insira a URL do Webhook',
        description: 'Escreva a descrição do Webhook',
      },
    },
    gateways: {
      main: 'Gateways',
      details: 'Detalhes',
    },
    token: {
      main: 'Tokens de API',
      new: 'Gerar token',
      newToken: 'Gerar token de API',
      success: 'Token gerado com sucesso!',
      error: 'Ocorreu um erro ao criar o token',
      created: 'Token criado',
      description:
        'Não compartilhe este token com ninguém. Ele é confidencial e não será exibido novamente.',
      confirmDeleteMessage:
        'Integrações com aplicativos e sites que utilizam esse token irão parar de funcionar imediatamente.',
      deleteSuccess: 'Token excluído com sucesso.',
      deleteError: 'Erro ao excluir o token.',
      copySuccess: 'Código copiado com sucesso!',
    },
    alert: {
      title: 'Deseja excluir essa integração?',
      actions: {
        confirmText: 'Sim, excluir',
        cancelText: 'Cancelar',
      },
    },
    messages: {
      createdWithSuccess: 'Integração criada com sucesso!',
      deletedWithSuccess: 'Integração excluída com sucesso!',
      tryAgainLater: 'Tente novamente mais tarde',
    },
    labels: {
      metaDescription: 'Descrição',
      productId: 'Produto',
    },
    schema: {
      productIsRequired: 'Produto é obrigatório',
      metaDescriptionIsRequired: 'A descrição é obrigatória',
    },
  },
  paymentPage: {
    helmetTitle: 'Pagamento',
    total: 'Valor',
    paymentInfo: 'Informações do Pagamento',
    paymentMethod: 'Pagamento',
    company: 'Empresa',
    support: 'Suporte',
    date: 'Data',
    paymentId: 'Identificador',
    customerData: 'Dados do Compador',
    customer: 'Cliente',
    contact: 'Contato',
    orderResume: 'Resumo do Pedido',
    dialogModal: {
      title: 'Pagamento Aprovado',
      button: 'Continuar',
      description: 'Você será redirecionado em',
    },
  },
  paymentStatusCard: {
    infoStatusText: 'Aguardando pagamento...',
    infoPaymentDescription:
      'Estamos aguardando a confirmação do pagamento para concluir o seu pedido.',
    successStatusText: 'Pagamento aprovado',
    successPaymentDescription: {
      first:
        'Seu pagamento foi aprovado, em breve o produtor entrará em contato com você através do email ',
      second: ' com as instruções para acessar o produto.',
    },
    errorStatusText: 'Pedido cancelado',
    errorPaymentDescription: 'Seu pagamento não foi reconhecido, foi recusado ou reembolsado.',
  },
  support: {
    main: 'Suporte',
    caption: 'Precisa de ajuda?',
    openChat: 'Abrir chat',
  },
  membership: {
    main: 'Área de membros',
    initing: 'Não foi possivel acessar a àrea de membros!',
  },
  eventz: {
    main: 'Eventos',
  },
  devices: {
    main: 'Maquininhas',
    edit: 'Editar',
    synchronize: 'Sincronizar',
    active: 'Ativa',
    available: 'Disponível',
    inactive: 'Desativada',
    detach: 'Desassociar',
    attachPartner: 'Associar/Desassociar Parceiro',
    messages: {
      attachedSuccessfully: 'Maquina associada com sucesso',
      detachedSuccessfully: 'Maquina desassociada com sucesso',
      sincronizedWithSuccess: 'Sincronizado com sucesso',
      tryAgainLater: 'Tente novamente mais tarde',
    },
    schemaValidation: {
      partnerRequired: 'Parceiro é obrigatório',
    },
  },
  charges: {
    schema: {
      selectType: 'Selecione o tipo',
      pixCode: 'Pix copia e cola',
      barcode: 'Código de barras',
    },
    installmentStepTitle: 'Informações da transação',
    pay: 'Pagar agora',
    sendPix: 'Enviar Pix',
    payBoleto: 'Pagar Boleto',
    additionalInfo: 'Informações adicionais',
    paymentInfo: 'Forma de pagamento',
    info: 'Informações do pagamento',
    voucher: 'Comprovante',
    voucherDetails: 'Detalhes do comprovante',
    checkVoucher: 'Ver comprovante',
    endToEnd: 'Código de transação',
    pixKey: 'Pagamento',
    reference: 'Referência',
    seeMore: 'Ver mais',
    paidAmount: 'Valor pago',
    main: 'Pagamentos',
    new: 'Novo pagamento',
    details: 'Detalhes',
    name: 'Nome',
    status: 'Status',
    createdAt: 'Data de criação',
    paidAt: 'Pago em',
    description: 'Descrição',
    amount: 'Valor',
    close: 'Fechar',
    id: 'ID',
    type: 'Tipo',
    document: 'Documento',
  },
}

export default ptBR
