// @mui
import { GlobalStyles as MUIGlobalStyles } from '@mui/material'

const inputFieldStyles = {
  // // fontSize: '16px',
  // // position: 'relative',
  // top: 0,
  // left: 0,
  // transform: 'scale(1)',
}

export default function GlobalStyles() {
  const inputGlobalStyles = (
    <MUIGlobalStyles
      styles={{
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          margin: 0,
          padding: 0,
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
          '&:focus': {
            ...inputFieldStyles,
          },
        },
        'text-area': {
          '&:focus': {
            ...inputFieldStyles,
          },
        },
        select: {
          '&:focus': {
            ...inputFieldStyles,
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
      }}
    />
  )

  return inputGlobalStyles
}
