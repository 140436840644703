import { forwardRef, useCallback, useImperativeHandle, useState } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'

import { LoadingButton } from '@mui/lab'
import PropTypes from 'prop-types'

export const Alert = forwardRef(
  (
    { title, message, challenge, actions = {}, onClose = () => {}, onConfirm = () => {}, ...props },
    ref
  ) => {
    const [open, setOpen] = useState(false)
    const [challengeResponse, setChallengeResponse] = useState('')

    const [isClosing, setIsClosing] = useState(false)
    const [isConfirming, setIsConfirming] = useState(false)

    const close = useCallback(() => {
      setOpen(false)
      setIsClosing(false)
      setIsConfirming(false)
      setChallengeResponse('')
    }, [])

    const handleClose = useCallback(async () => {
      setIsClosing(true)
      try {
        await onClose?.()
      } finally {
        close()
        setIsClosing(false)
      }
    }, [close, onClose])

    const handleConfirm = useCallback(async () => {
      setIsConfirming(true)
      try {
        await onConfirm().then(() => setIsConfirming(false))
      } catch (error) {
        console.error(error)
      }

      close()
    }, [close, onConfirm])

    useImperativeHandle(ref, () => ({
      open() {
        setOpen(true)
      },
      close() {
        handleClose()
      },
    }))

    return (
      <Dialog maxWidth="xs" open={open} onClose={handleClose} scroll="paper" {...props}>
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ mb: challenge ? 2 : 0 }}>{message}</DialogContentText>

          <Typography variant="caption">{challenge?.question}</Typography>
          {challenge && (
            <TextField
              fullWidth
              size="small"
              variant="standard"
              value={challengeResponse}
              onChange={(event) => {
                setChallengeResponse(event.target.value)
              }}
            />
          )}
        </DialogContent>

        <DialogActions>
          {actions.cancelText && (
            <LoadingButton
              variant="soft"
              color="inherit"
              loading={isClosing}
              disabled={isConfirming}
              {...actions.cancelButtonProps}
              onClick={handleClose}
            >
              {actions.cancelText}
            </LoadingButton>
          )}

          <LoadingButton
            loading={isConfirming}
            variant="soft"
            autoFocus
            title={challenge?.question}
            disabled={(challenge && challenge?.value !== challengeResponse) || isClosing}
            {...actions.confirmButtonProps}
            onClick={handleConfirm}
          >
            {actions.confirmText || 'Confirmar'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    )
  }
)

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  challenge: PropTypes.object,
  actions: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}
