import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Link } from '@mui/material'
import { PATH_DASHBOARD } from '../../routes/paths'
import { useWhiteLabel } from '../../hooks/useWhiteLabel'

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const whitelabel = useWhiteLabel()

  const image = whitelabel.logo?.site

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        maxWidth: 96,
        whiteSpaceidth: '100%',
        mx: 'auto',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      {image && <img src={image} alt="logo" style={{ maxWidth: '100%', height: 'auto' }} />}
    </Box>
  )

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} to={PATH_DASHBOARD.general.app} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
}

export default Logo
