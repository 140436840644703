import PropTypes from 'prop-types'
// @mui
import { useTheme } from '@mui/material/styles'
import { Card, Typography, Stack } from '@mui/material'
// utils
import { centsToBRL } from '../../../../utils/formatNumber'
// components
import Iconify from '../../../../components/iconify'
import Chart, { useChart } from '../../../../components/chart'

// ----------------------------------------------------------------------

BankingWidgetSummary.propTypes = {
  sx: PropTypes.object,
  chart: PropTypes.object,
  color: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  info: PropTypes.string,
  blur: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default function BankingWidgetSummary({
  title,
  total,
  icon,
  info,
  color = 'primary',
  chart,
  sx,
  blur = true,
  ...other
}) {
  const theme = useTheme()

  const { series, options } = chart

  const chartOptions = useChart({
    colors: [theme.palette[color].main],
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    xaxis: {
      labels: { show: false },
    },
    yaxis: {
      labels: { show: false },
    },
    stroke: {
      width: 4,
    },
    legend: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    fill: {
      gradient: {
        opacityFrom: 0.56,
        opacityTo: 0.56,
      },
    },
    ...options,
  })

  return (
    <Card
      sx={{
        width: 1,
        boxShadow: 0,
        color: theme.palette[color].darker,
        bgcolor: theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          p: 1.5,
          top: 24,
          right: 24,
          width: 48,
          height: 48,
          borderRadius: '50%',
          position: 'absolute',
          color: theme.palette[color].lighter,
          bgcolor: theme.palette[color].dark,
        }}
      />

      <Stack spacing={1} sx={{ p: 3 }}>
        <Typography variant="subtitle2">{title}</Typography>

        <Typography variant="h3" sx={{ filter: blur ? 'blur(8px)' : 'blur(0px)' }}>
          {!Number.isNaN(Number(total)) ? centsToBRL(total) : total}
        </Typography>

        <TrendingInfo info={info} />
      </Stack>

      <Chart type="area" series={[{ data: series }]} options={chartOptions} height={120} />
    </Card>
  )
}

// ----------------------------------------------------------------------

TrendingInfo.propTypes = {
  info: PropTypes.string,
}

function TrendingInfo({ info }) {
  return (
    <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={0.5} sx={{ minHeight: 20 }}>
      {info && <Iconify icon="eva:info-outline" />}

      <Typography variant="subtitle2" component="span">
        {info}
      </Typography>
    </Stack>
  )
}
