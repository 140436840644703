import * as React from 'react'
import PropTypes from 'prop-types'
import { useRef, useCallback } from 'react'

// @mui
import { useTheme } from '@mui/material/styles'
import { Stack, AppBar, Toolbar, IconButton, Tooltip, Typography, Paper } from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { bgBlur } from '../../../utils/cssStyles'

// hooks
import useOffSetTop from '../../../hooks/useOffSetTop'
import useResponsive from '../../../hooks/useResponsive'
// config
import { HEADER, NAV } from '../../../config-global'
// components

import Logo from '../../../components/logo'
import Iconify from '../../../components/iconify'
import { useSettingsContext } from '../../../components/settings'
import Can from '../../../components/can'

import ImpersonateFormModal from './ImpersonateFormModal'
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths'
import { useAuthContext } from '../../../auth/useAuthContext'
import { useSnackbar } from '../../../components/snackbar'
import { useLocales } from '../../../locales'
import Render from '../../../components/conditional/Render'
import SimulatorModal from '../../../components/simulator-modal'

Header.propTypes = {
  onOpenNav: PropTypes.func,
}

export default function Header({ onOpenNav }) {
  const theme = useTheme()
  const navigate = useNavigate()
  const impersonateModal = useRef()
  const simulatorModal = useRef()

  const { logout } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { translate } = useLocales()
  const { user, impersonate } = useAuthContext()

  const handleCloseImpersonate = React.useCallback(async () => {
    try {
      await impersonate(null)
      navigate(PATH_DASHBOARD.general.app)
      document.location.reload(true)
    } catch (error) {
      console.error(error)
      enqueueSnackbar(translate('impersonate.tryAgainLater'), { variant: 'error' })
    }
  }, [enqueueSnackbar, navigate, impersonate, translate])

  const { themeLayout } = useSettingsContext()

  const isNavHorizontal = themeLayout === 'horizontal'

  const isNavMini = themeLayout === 'mini'

  const isDesktop = useResponsive('up', 'lg')

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal

  const onClose = useCallback(() => {}, [])

  const handleImpersonate = useCallback(() => {
    impersonateModal.current?.open()
  }, [])

  const handleLogout = async () => {
    try {
      logout()
      navigate(PATH_AUTH.login, { replace: true })
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Unable to logout!', { variant: 'error' })
    }
  }

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      <Stack spacing={1} direction="row">
        {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}

        {/* <Searchbar /> */}

        <Can perform="users:impersonate">
          <Render if={user.partners?.length === 0}>
            <IconButton onClick={handleImpersonate} sx={{ transform: 'rotateY(180deg)' }}>
              <Iconify icon="twemoji:bird" width={28} />
            </IconButton>
          </Render>

          <Render if={user.partners?.length !== 0}>
            <Paper>
              <Stack spacing={0.5} direction="row" alignItems="center" sx={{ pl: 1, py: 0.5 }}>
                <div>
                  <Typography variant="body2">{user.partners[0]?.name}</Typography>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {`${translate('listColumns.document')} : ${user.partners[0]?.document}`}
                  </Typography>
                </div>

                <IconButton onClick={handleCloseImpersonate}>
                  <Iconify icon="solar:close-circle-bold-duotone" width={28} />
                </IconButton>
              </Stack>
            </Paper>
          </Render>
        </Can>
      </Stack>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <Can perform="simulator:simulate">
          <IconButton onClick={() => simulatorModal?.current.open()} aria-label="simulator">
            <Iconify icon="solar:calculator-bold-duotone" width={28} />
          </IconButton>
        </Can>

        <Tooltip title="Sair">
          <IconButton onClick={handleLogout}>
            <Iconify icon="mingcute:power-fill" width={28} sx={{ opacity: 0.8 }} />
          </IconButton>
        </Tooltip>
      </Stack>
    </>
  )

  return (
    <>
      <ImpersonateFormModal ref={impersonateModal} onClose={onClose} />
      <SimulatorModal ref={simulatorModal} />

      <AppBar
        sx={{
          boxShadow: 'none',
          height: HEADER.H_MOBILE,
          zIndex: theme.zIndex.appBar + 1,
          ...bgBlur({
            color: theme.palette.background.default,
          }),
          transition: theme.transitions.create(['height'], {
            duration: theme.transitions.duration.shorter,
          }),
          ...(isDesktop && {
            width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
            height: HEADER.H_DASHBOARD_DESKTOP,
            ...(isOffset && {
              height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            }),
            ...(isNavHorizontal && {
              width: 1,
              bgcolor: 'background.default',
              height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
              borderBottom: `dashed 1px ${theme.palette.divider}`,
            }),
            ...(isNavMini && {
              width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
            }),
          }),
        }}
      >
        <Toolbar
          sx={{
            height: 1,
            px: { lg: 5 },
          }}
        >
          {renderContent}
        </Toolbar>
      </AppBar>
    </>
  )
}
