import { Link as RouterLink } from 'react-router-dom'

// @mui
import { Link, Stack, Typography } from '@mui/material'
// routes
import { PATH_AUTH } from '../../routes/paths'
// layouts
import LoginLayout from '../../layouts/login'
//

import AuthLoginForm from './AuthLoginForm'
import { useLocales } from '../../locales'

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales()
  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: 'relative', textAlign: 'center' }}>
        <Typography variant="h4">{translate('login.main')}</Typography>

        <Stack direction="row" spacing={0.5} alignSelf="center">
          <Typography variant="body2">{translate('login.newAccount')}</Typography>

          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            {translate('login.createAccount')}
          </Link>
        </Stack>

        <AuthLoginForm />

        <Stack direction="row" spacing={0.5} alignSelf="center">
          <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            {translate('login.register')}
          </Link>
        </Stack>
      </Stack>
    </LoginLayout>
  )
}
