import numeral from 'numeral'

// ----------------------------------------------------------------------
export const currency = [
  { mask: '0,00' },
  { mask: '00,00' },
  { mask: '000,00' },
  { mask: '0.000,00' },
  { mask: '00.000,00' },
  { mask: '000.000,00' },
  { mask: '0.000.000,00' },
  { mask: '00.000.000,00' },
  { mask: '000.000.000,00' },
  { mask: '0.000.000.000,00' },
]

export const percentage = [{ mask: '0.00' }, { mask: '00.00' }]

export function fNumber(number) {
  return numeral(number).format()
}

export function fCurrency(number) {
  const format = number ? numeral(number).format('$0,0.00') : ''

  return result(format, '.00')
}

export function fPercent(number) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : ''

  return result(format, '.0')
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : ''

  return result(format, '.00')
}

export function fData(number) {
  const format = number ? numeral(number).format('0.0 b') : ''

  return result(format, '.0')
}

function result(format, key = '.00') {
  const isInteger = format.includes(key)

  return isInteger ? format.replace(key, '') : format
}

export const formatNumber = (amount, scale = 2, radix = ',', mapToRadix = '.') => {
  amount = Math.abs(Number(amount) || 0).toFixed(scale)

  scale = Math.abs(scale)
  scale = Number.isNaN(scale) ? 2 : scale

  const negativeSign = amount < 0 ? '-' : ''

  const i = parseInt(amount, 10).toString()

  const j = i.length > 3 ? i.length % 3 : 0

  return (
    negativeSign +
    (j ? i.substr(0, j) + mapToRadix : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${mapToRadix}`) +
    (scale
      ? radix +
        Math.abs(amount - i)
          .toFixed(scale)
          .slice(2)
      : '')
  )
}

export const numberToBRL = (number) => `R$ ${formatNumber(number)}`

export const centsToBRL = (number, abs = false) => {
  number = abs ? Math.abs(Number(number)) : Number(number)

  const signal = number < 0 ? '-' : ''

  return `${signal}R$ ${formatNumber(number / 100)}`
}
