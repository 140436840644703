export function getInvalidFields(error) {
  try {
    const responseError = error.response?.data?.message

    if (Array.isArray(responseError)) {
      const messages = error?.response?.data?.message?.map((errorMessage) => {
        const [[, message = []]] = Object.entries(errorMessage)
        return message?.[0]
      })

      return messages.find(Boolean)
    }

    if (typeof responseError === 'string') {
      return responseError
    }

    return ''
  } catch (exception) {
    console.info(error, exception)
    return ''
  }
}

export function getResponseError(error, defaultMessage) {
  const validationError = getInvalidFields(error)

  if (validationError) {
    return validationError
  }

  const defaults = {
    401: 'Você precisa fazer login',
    422: 'Verifique os dados e tente novamente',
    403: 'Você não possui permissão para usar este recurso',
    0: 'Verifique sua conexão com a internet',
  }

  defaultMessage =
    defaults[error.response?.status] || defaultMessage || 'Tente novamente em alguns instantes'

  const messages = error?.message

  try {
    let firstMessage = ''

    const validatorError = Array.isArray(messages) ? messages.find(Boolean) : messages

    if (validatorError) {
      const [key] = Object.keys(validatorError)
      if (key) {
        firstMessage = validatorError[key].find(Boolean)
      }
    }

    return firstMessage || defaultMessage
  } catch (err) {
    console.warn(err)
    return defaultMessage
  }
}

const getFilterType = (name, customPatters = []) => {
  const defaultType = 'like'

  const patterns = [
    ...customPatters,
    ['_id', 'where'],
    ['_at', 'between'],
    ['date', 'between'],
    ['data', 'between'],
    ['search', 'search'],
  ]

  const [, type] = patterns.find(([pattern]) => name.match(pattern)) || []

  return type || defaultType
}

const formatValue = (key, value) => {
  if (Array.isArray(value)) {
    return value.map((item) => formatValue(key, item).find(Boolean))
  }

  if (value instanceof Object) {
    return Object.entries(value)
      .filter(([, innerValue]) => !(innerValue == null || innerValue === ''))
      .map(
        ([innerKey, innerValue]) => formatValue(innerKey, innerValue).find(Boolean) // ignore deeper values
      )
  }
  return [`${key},${value}`]
}

export function formatLaravelFilters(params, customPatters = {}) {
  const paramsFormatted = Object.entries(params)
    .filter(([key, value]) => key !== 'from' && !(value == null || value === ''))
    .reduce(
      (formatted, [key, value]) => {
        if (customPatters[key] === 'raw') {
          formatted[key] = value
          return formatted
        }

        key = String(key).replace(/:/g, '.') // use deep props in laravel

        const type = getFilterType(key, Object.entries(customPatters))

        formatted[type].push(...formatValue(key, value))

        return formatted
      },
      { like: [], where: [], search: [], between: [] }
    )

  return paramsFormatted
}
