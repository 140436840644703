import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useMemo, useRef, useState } from 'react'
// @mui
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Slider,
  Stack,
  Typography,
} from '@mui/material'
// utils
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Eye, EyeClosed } from 'phosphor-react'
import { centsToBRL } from '../../../../utils/formatNumber'
// components
import ConfirmTransferModal from './ConfirmTransferModal'
import { useBalance } from '../../../../hooks/balance'
import { useLocales } from '../../../../locales'
import BankAccountCard from '../../../../components/bankaccountcard'
import FormProvider from '../../../../components/hook-form/FormProvider'
import { RHFTextField } from '../../../../components/hook-form'

// ----------------------------------------------------------------------

export default function BankingQuickTransfer({
  title,
  subheader,
  sx,
  bankAccounts,
  onSave,
  ...other
}) {
  const { translate } = useLocales()
  const transferRef = useRef()
  const [amount, setAmount] = useState('')
  const [openConfirm, setOpenConfirm] = useState(false)
  const [selectBankAccount, setSelectBankAccount] = useState(null)
  const { totals, isVisible: isBalanceVisible, toggle: toggleBalance } = useBalance()

  const amountSchema = Yup.object().shape({
    amount: Yup.number()
      .nullable()
      .moreThan(0.0, `${translate('validators.shouldBeMoreThan')} R$ 0,01`)
      // eslint-disable-next-line no-unsafe-optional-chaining
      .max(totals?.available / 100 || 0, translate('validators.mustBeLessThan'))
      .transform((_, val) => (val ? Number(val) : null)),
  })

  const defaultValues = useMemo(
    () => ({
      amount: amount || '',
    }),
    [amount]
  )

  const methods = useForm({
    resolver: yupResolver(amountSchema),
    defaultValues,
  })

  const { handleSubmit, setValue } = methods

  const handleOpenConfirm = () => {
    if (amount > 0 && selectBankAccount) {
      setOpenConfirm(true)
    }
  }

  const handleChangeSlider = (event, newValue) => {
    if (newValue > totals.available) {
      newValue = totals.available
    }
    setAmount(newValue)
    setValue('amount', newValue, { shouldValidate: true })
  }

  const onChangeBankAccount = (event, newValue) => {
    if (newValue) {
      setSelectBankAccount(bankAccounts?.find((acc) => acc.id === newValue.id))
    } else {
      setSelectBankAccount(null)
    }
  }

  const handleInputChange = (event) => {
    const newValue = event.target.value
    setAmount(newValue)
    setValue('amount', newValue, { shouldValidate: true })
  }

  const handleBlur = () => {
    if (amount < 0) {
      setAmount(0)
    } else if (amount > totals?.available) {
      setAmount(totals.available / 100)
    }

    setValue('amount', amount, { shouldValidate: true })
  }

  /* eslint-disable no-unsafe-optional-chaining */
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleOpenConfirm)}>
        <Card sx={{ boxShadow: 0, bgcolor: 'background.neutral', ...sx }} {...other}>
          <CardHeader title={title} subheader={subheader} />

          <CardContent>
            <BankAccountCard
              bankAccounts={bankAccounts}
              onChangeBankAccount={onChangeBankAccount}
            />
            <Stack spacing={3}>
              <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                {translate('transfers.quickTransfer.amount')}
              </Typography>
              <Stack direction="row" justifyContent="center" spacing={1} sx={sx}>
                <Typography variant="h5">R$</Typography>
                <RHFTextField
                  variant="standard"
                  fullWidth
                  name="amount"
                  placeholder="0"
                  value={amount}
                  disabled={totals?.available / 100 <= 0}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    type: 'number',
                    disableUnderline: true,
                  }}
                  sx={{
                    '& input': {
                      p: 0,
                      fontSize: '250%',
                      textAlign: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Stack>

              <Slider
                disabled={totals?.available / 100 <= 0}
                value={amount}
                valueLabelDisplay="auto"
                step={1}
                min={0}
                marks
                max={totals?.available / 100 <= 0 ? 0 : totals?.available / 100}
                onChange={handleChangeSlider}
              />

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  {translate('transfers.quickTransfer.balance')}
                </Typography>
                <Stack spacing={1} direction="row" alignItems="center">
                  <Typography
                    variant="subtitle1"
                    sx={{ filter: isBalanceVisible ? 'blur(0px)' : 'blur(4px)' }}
                  >
                    {totals?.available < 0 ? 'R$ •••,••' : centsToBRL(totals?.available)}
                  </Typography>

                  <IconButton onClick={toggleBalance}>
                    {!isBalanceVisible ? <EyeClosed size={20} /> : <Eye size={20} />}
                  </IconButton>
                </Stack>
              </Stack>

              <Button
                variant="contained"
                size="large"
                disabled={amount === 0 || !selectBankAccount}
                type="submit"
              >
                {translate('transfers.quickTransfer.now')}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </FormProvider>

      <ConfirmTransferModal
        open={openConfirm}
        ref={transferRef}
        onSave={onSave}
        amount={amount}
        bankAccount={selectBankAccount}
        onClose={() => setOpenConfirm(false)}
      />
    </>
  )
}

BankingQuickTransfer.propTypes = {
  sx: PropTypes.object,
  list: PropTypes.array,
  bankAccounts: PropTypes.array,
  onSave: PropTypes.func,
  title: PropTypes.string,
  subheader: PropTypes.string,
}
