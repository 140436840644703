import { useSessionStorageState } from 'ahooks'
import { createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import axios from '../utils/axios'
import { HOST_API_KEY } from '../config-global'
import { useSettingsContext } from '../components/settings'

const WhiteLabelContext = createContext({})

export function WhiteLabelProvider({ children }) {
  const { onUpdatePresetsColor, setThemeMode } = useSettingsContext()

  const [whitelabel, setWhitelabel] = useSessionStorageState('whitelabel', {
    defaultValue: {},
  })

  useEffect(() => {
    async function fetchWhitelabel() {
      try {
        const { data } = await axios.get(`${HOST_API_KEY}/api/config/tenant`)
        setWhitelabel(data)
      } catch (err) {
        console.error('Failed to fetch whitelabel configuration:', err)
      }
    }

    if (!whitelabel?.id) {
      fetchWhitelabel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (whitelabel.id) {
      onUpdatePresetsColor(whitelabel.theme.palette.primary)
      setThemeMode(whitelabel.theme.mode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [whitelabel])

  return <WhiteLabelContext.Provider value={whitelabel}>{children}</WhiteLabelContext.Provider>
}

WhiteLabelProvider.propTypes = {
  children: PropTypes.node,
}

export function useWhiteLabel() {
  const context = useContext(WhiteLabelContext)
  if (context === null) {
    throw new Error('useWhiteLabel must be used within a WhiteLabelProvider')
  }
  return context
}
