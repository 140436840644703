import PropTypes from 'prop-types'
// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material'
import { useLocales } from '../../locales'

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
  cancelText: PropTypes.string,
  showCancelButton: PropTypes.bool,
}

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  onClose,
  cancelText,
  showCancelButton = true,
  ...other
}) {
  const { translate } = useLocales()

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        {action}
        {showCancelButton && (
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {cancelText || translate('actions.cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
