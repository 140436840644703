import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { Box, Stack, Drawer, Button } from '@mui/material'
import * as React from 'react'
import useResponsive from '../../../hooks/useResponsive'
import { NAV } from '../../../config-global'
import Logo from '../../../components/logo'
import Scrollbar from '../../../components/scrollbar'
import { NavSectionVertical } from '../../../components/nav-section'
import { useNavData } from './config-navigation'
import NavToggleButton from './NavToggleButton'
import UserBalance from '../../../components/user-balance'
import Iconify from '../../../components/iconify'
import TransferFormModal from '../../../pages/dashboard/Transfers/TransferFormModal'
import DepositFormModal from '../../../pages/dashboard/DepositFormModal'
import { useLocales } from '../../../locales'
import { useAuthContext } from '../../../auth/useAuthContext'
import Can from '../../../components/can'

NavVertical.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
}

export default function NavVertical({ openNav, onCloseNav }) {
  const { pathname } = useLocation()
  const navConfig = useNavData()
  const isDesktop = useResponsive('up', 'lg')
  const transfer = useRef()
  const deposit = useRef()
  const { translate } = useLocales()
  const { partner } = useAuthContext()

  useEffect(() => {
    if (openNav) {
      onCloseNav()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          alignItems: 'center',
        }}
      >
        <Logo />

        <Can perform={'partner:balance'}>
          <UserBalance />
        </Can>

        {partner?.id && (
          <Stack direction="row" spacing={1}>
            <Can perform={'transfers:create'}>
              <Button
                fullWidth
                onClick={() => transfer.current?.open()}
                variant="soft"
                color="inherit"
                endIcon={<Iconify icon="solar:square-arrow-up-line-duotone" />}
              >
                {translate('payments.withdraw')}
              </Button>
            </Can>

            <Can perform={'payments:create'}>
              <Button
                fullWidth
                onClick={() => deposit.current?.open()}
                variant="soft"
                color="inherit"
                endIcon={<Iconify icon="solar:square-arrow-down-line-duotone" />}
              >
                {translate('payments.newDeposit')}
              </Button>
            </Can>
          </Stack>
        )}
      </Stack>

      <NavSectionVertical data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <TransferFormModal ref={transfer} />
      <DepositFormModal ref={deposit} />
    </Scrollbar>
  )

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      <NavToggleButton />

      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  )
}
