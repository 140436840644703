import { format as dateFormat, parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { Card, CardHeader } from '@mui/material'
import { Stack } from '@mui/system'
import * as React from 'react'
import { centsToBRL } from '../../../../utils/formatNumber'
import DataGridRemote from '../../../../components/DataGridRemote'
import Label from '../../../../components/label'
import { useLocales } from '../../../../locales'
import useColors from '../../../../hooks/useColors'
import useConfigs from '../../../../hooks/useConfig'

BankingRecentTransitions.propTypes = {
  title: PropTypes.string,
  tableData: PropTypes.array,
  subheader: PropTypes.string,
  tableLabels: PropTypes.array,
}

export default function BankingRecentTransitions({
  title,
  subheader,
  tableLabels,
  tableData,
  ...other
}) {
  const { translate } = useLocales()
  const { colors } = useColors()
  const table = useRef()

  const { configs } = useConfigs()

  const columns = [
    {
      headerName: translate('listColumns.resume'),
      field: 'created_at',
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <strong>{configs?.receiptTypes?.labels[params?.row?.type]}</strong>
          {params && dateFormat(parseISO(params?.value), 'dd/MM/yyyy HH:mm')}
        </Stack>
      ),
    },
    {
      headerName: translate('listColumns.status'),
      field: 'status',
      flex: 1,
      renderCell: (params) => (
        <Label color={colors.receipts.status[params?.value]}>{translate(params?.value)}</Label>
      ),
    },
    {
      headerName: translate('listColumns.amount'),
      field: 'amount',
      flex: 1,
      valueFormatter: (params) => centsToBRL(params?.value),
    },
    {
      headerName: translate('listColumns.net_amount'),
      field: 'net_amount',
      flex: 1,
      valueFormatter: (params) => centsToBRL(params?.value),
    },
  ]

  return (
    <Card {...other}>
      <CardHeader title={translate('lastReceipts')} />
      <DataGridRemote
        ref={table}
        columns={columns}
        endpoint="api/receipts?per_page=5"
        mobileOptions={{
          valuesToShow: ['type', 'status', 'created_at', 'amount', 'net_amount'],
          formatValues: {
            type: (params) => <Label color="info">{configs?.receiptTypes?.labels[params]}</Label>,
            status: (params) =>
              params && <Label color={colors.receipts.status[params]}>{translate(params)}</Label>,
            created_at: (params) => params && dateFormat(parseISO(params), 'dd/MM/yyyy HH:mm'),
            amount: (params) => centsToBRL(params),
            net_amount: (params) => centsToBRL(params),
          },
          translateLabels: {
            created_at: translate('receipts.created_at'),
          },
        }}
      />
    </Card>
  )
}
