import { useRequest } from 'ahooks'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { useAuthContext } from '../auth/useAuthContext'
import { MEMBERSHIP } from '../config-global'
import { useLocales } from '../locales'
import { PATH_MEMBERSHIP } from '../routes/paths'
import axiosInstance from '../utils/axios'
import { useWhiteLabelSettings } from './useWhiteLabelSettings'

const getCacheTime = (minutes = 0) => minutes * 60 * 1000

const getCacheKey = (resource, partnerId) => `members-${resource}:${partnerId}`

const getMembershipPath = (endpoint) =>
  `/api/integrations/gateway/api/providers/reding-members/${endpoint}`.replace(/\/\//g, '/')

const getProvider = async () => {
  const { data } = await axiosInstance.get(`api/integrations/providers/${MEMBERSHIP.PROVIDER_ID}`)
  return data
}

const authenticate = async () => {
  const { data } = await axiosInstance.post(getMembershipPath('/impersonate'))
  return data
}

const listClubs = async () => {
  const { data } = await axiosInstance.get(getMembershipPath('/clubs'))
  return data
}

const listPlans = async () => {
  const { data } = await axiosInstance.get(getMembershipPath('/plans'))
  return data
}

/**
 * Custom React hook for managing membership operations.
 *
 * @returns {{
 *   isLoading: boolean,
 *   openDashboard: () => void,
 *   openClubsList: () => void,
 *   openPlansList: () => void,
 *   provider: import('ahooks/lib/useRequest/src/types').Result,
 *   clubsList: import('ahooks/lib/useRequest/src/types').Result,
 *   plansList: import('ahooks/lib/useRequest/src/types').Result
 * }}
 */
export function useMembership({ manual = true } = {}) {
  const { partner } = useAuthContext()
  const { enqueueSnackbar } = useSnackbar()
  const { MS_MEMBERS_APP: whiteLabelURL } = useWhiteLabelSettings()
  const { translate } = useLocales()

  const {
    data: authData,
    loading: isLoading,
    error: authError,
    runAsync: runAuth,
  } = useRequest(authenticate, {
    manual,
    refreshDeps: [partner?.id],
    retryCount: 3,
    retryInterval: 10,
    debounce: 50000,
    staleTime: getCacheTime(5),
    cacheKey: getCacheKey('auth', partner?.id),
    onError: (error) => enqueueSnackbar(translate('membership.initing'), { variant: 'error' }),
  })

  const isReady = !isLoading && !authError

  const provider = useRequest(getProvider, {
    manual: true,
    staleTime: getCacheTime(5),
    cacheKey: getCacheKey('provider', MEMBERSHIP.PROVIDER_ID),
  })

  const clubsList = useRequest(listClubs, {
    manual: true,
    refreshDeps: [partner?.id],
    cacheKey: getCacheKey('clubs', partner?.id),
  })

  const plansList = useRequest(listPlans, {
    manual: true,
    refreshDeps: [partner?.id],
    cacheKey: getCacheKey('plans', partner?.id),
  })

  const getAuthData = useCallback(async () => {
    if (manual && !authData?.access_token) {
      return runAuth()
    }

    return authData
  }, [authData, manual, runAuth])

  const openMemberhipPage = useCallback(
    async (path) => {
      const { access_token } = await getAuthData()

      if (isReady) {
        const query = new URLSearchParams()

        query.append('token', access_token)
        query.append('returnTo', path)

        const url = `${whiteLabelURL}/auth/jwt/token?${query.toString()}`

        window.open(url, '_blank').focus()
      }
    },
    [getAuthData, isReady, whiteLabelURL]
  )

  const openDashboard = useCallback(() => {
    openMemberhipPage(PATH_MEMBERSHIP.dashboard.root)
  }, [openMemberhipPage])

  const openClubsList = useCallback(() => {
    openMemberhipPage(PATH_MEMBERSHIP.dashboard.clubs.list)
  }, [openMemberhipPage])

  const openPlansList = useCallback(() => {
    openMemberhipPage(PATH_MEMBERSHIP.dashboard.plans.list)
  }, [openMemberhipPage])

  return useMemo(
    () => ({
      isReady,
      isLoading,
      openDashboard,
      openClubsList,
      openPlansList,
      provider,
      clubsList,
      plansList,
    }),
    [
      isReady,
      isLoading,
      provider,
      openDashboard,
      openClubsList,
      openPlansList,
      clubsList,
      plansList,
    ]
  )
}
