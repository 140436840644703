import * as React from 'react'
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { Card, Skeleton } from '@mui/material'
import { BankingQuickTransfer } from '../../../sections/@dashboard/general/banking'
import axios from '../../../utils/axios'
import { useLocales } from '../../../locales'
import Render from '../../../components/conditional/Render'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
}

const TransferFormModal = forwardRef(
  ({ onClose = () => {}, onContinue, onSave, ...props }, ref) => {
    const [open, setOpen] = useState(false)
    const { translate } = useLocales()
    const [isLoading, setIsLoading] = useState(true)
    const [accounts, setAccounts] = useState([])
    const modalConfirmation = useRef()

    const handleOpen = () => {
      onOpen()
      setOpen(true)
    }

    const onOpen = useCallback(async () => {
      try {
        setIsLoading(true)
        const { data: content } = await axios.get('/api/bank-accounts?paginate=false')
        setAccounts(content)
      } catch (e) {
        console.warn(e)
      } finally {
        setIsLoading(false)
      }
    }, [])

    const handleClose = React.useCallback(() => {
      onClose()
      setOpen(false)
    }, [onClose])

    const handleSave = useCallback(() => {
      onSave()
      setOpen(false)
    }, [onSave])

    useImperativeHandle(ref, () => ({
      open: handleOpen,
      close: handleClose,
    }))

    return (
      <Modal ref={modalConfirmation} open={open} onOpen={onOpen} onClose={handleClose}>
        <Box sx={{ ...style, width: 400, justifyContent: 'center' }}>
          <Render if={isLoading}>
            <Card sx={{ width: 400, height: 400, justifyContent: 'center' }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                sx={{ paddingTop: '100%', borderRadius: 2 }}
              />
            </Card>
          </Render>
          <Render if={!isLoading}>
            <BankingQuickTransfer
              title={translate('transfers.quickTransfer.main')}
              bankAccounts={accounts}
              onSave={handleSave}
            />
          </Render>
        </Box>
      </Modal>
    )
  }
)

TransferFormModal.propTypes = {
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  onContinue: PropTypes.func,
  bankAccounts: PropTypes.array,
}

export default TransferFormModal
